import React,{ useState, useEffect } from 'react'

import './fronthand_core_x.css';
import './fronthand_core_x_media-queries.css';

import { FS_Main } from './Data_Structure';
import { Check_Add } from './Data_Structure';

import Logo__3 from './front_assets/Logo__3.png';

import Scalability from './front_assets/Scalability.png';
import Availability from './front_assets/Availability.png';
import Profitable from './front_assets/Profitable.png';

import Completely from './front_assets/Completely.png';
import Complete from './front_assets/Complete.png';
import core_1 from './front_assets/core_1.png';

import freedom from './front_assets/freedom.png';

import faq_1 from './front_assets/faq_1.png';

import s_m_1 from './front_assets/s_m_1.png';


function Btn_Click(btn_id) {
    
    const btn = document.getElementById(btn_id);

    const ans = btn.nextElementSibling;
    const span2 = btn.children[1];
    const item1 = span2.children[0];
    
    //console.log(" ");
    //console.log(ans);
    //console.log(item1);
    //console.log(span2);
    //console.log(" ");

    ans.classList.toggle('show');
    item1.classList.toggle('rotate');
    //span2.classList.toggle("");
}

export default function CoreWorld_Before_Login(props) {

    return (
        <>
            {/* <!---Main--Content--------------------------> */}
            <div class="main-content">
                <header>
                    <div class="back-grid-header">
                        <div class="back-grid-header-left">
                            {/* <!-- <div class="logo"> <img src="./assets/Logo__3.png" alt=""></div> -->
                            <!-- <div class="logo"> <img src="./assets/Logo__1.png" alt=""></div> --> */}
                            <div class="logo">
                                <div>CORE</div>
                                <div>WORLD</div>
                            </div>
                            
                        </div>
                        <div class="back-grid-header-blank">
                            <span></span>
                        </div>
                        <div class="back-grid-header-right">
                            <div class="btn-link">
                                <div><a onClick={() => {props.SignIn_Click();}}>Log In</a></div>
                            </div>
                        </div>
                    </div>    
                </header>
                {/* <!--Main----Cards-----> */}
                <main>
                    <div class="back-grid">
                        <div class="dash-main">
                            <div class="dash-card-main">           
                                <div class="dash-main-left">
                                    <span>WELCOME YOU TO</span>
                                    <div>CORE WORLD</div>
                                    <p>
                                        A FUTURE REVOLUTION CONNECTING ALL DIGITAL PLATFORMS WORLDWIDE.
                                    </p>
                                    <span></span>
                                    <div class="btn-action">
                                        <span><a onClick={() => {props.SignIn_Click();}}>Log In</a></span>
                                        <span><a onClick={() => {props.SignUp_Click();}}>Sign Up</a></span>
                                    </div>
                                </div>
                                
                                <div class="dash-main-right">
                                    <div class="dash-img">
                                        <span><img src={Logo__3} alt=""/></span>
                                    </div>
                                </div>            
                            </div>
                        </div>
                    </div>
                    {/* <!------Smart---Contract---------------------------------> */}

                    <div class="back-grid-short">                                 
                        <div class="heading">
                            <span>Smart Contract</span>
                            <div class="sub-heading">
                                <span>Features Of</span>
                            </div>
                        </div>
                    </div>
                    <div class="back-grid">
                        <div class="dash-triple">
                            <div class="card-triple">
                                <div class="card-logo">            
                                    <span><img src={Scalability} alt=""/></span>
                                </div>
                                <div class="sub-heading">
                                    <span>Scalability</span>
                                </div>
                                <p>
                                    Applications are given a wider variety of ways to be 
                                    deployed in CORE because of its scalability and highly 
                                    effective smart contract.
                                </p>
                            </div>
                            <div class="card-triple">
                                <div class="card-logo">            
                                    <span><img src={Availability} alt=""/></span>
                                </div>
                                <div class="sub-heading">
                                    <span>Availability</span>
                                </div>
                                <p>
                                    More reliable network structure, user asset, intrinsic 
                                    value, and a higher degree of decentralization consensus come...
                                </p>
                            </div>
                            <div class="card-triple">
                                <div class="card-logo">            
                                    <span><img src={Profitable} alt=""/></span>
                                </div>
                                <div class="sub-heading">
                                    <span>Profit Generated</span>
                                </div>
                                <p>
                                    Referral bonus that pays up to Unlimited levels.
                                    Transparently programmed using a smart contract created 
                                    on the CORE Blockchain.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <!-----About----Section-------------------------------> */}
                    <div class="back-grid-short">                                 
                        <div class="heading">
                            <span>About</span>
                            <div class="sub-heading">
                                <span>Features Of</span>
                            </div>
                        </div>
                    </div>
                    <div class="back-grid">
                        <div class="dash-double">
                            <div class="card-double-blank">
                                <div class="card-double">
                                    <div>
                                        <span><img src={Logo__3} alt=""/></span>
                                    </div>
                                </div>
                                <div class="card-double">    
                                    <div class="sub-heading">
                                        <span>About</span>
                                    </div>
                                    <p>
                                        Core World is the international community of the global decentralized ecosystem and the first ever 
                                        smart contract marketing matrix plan of the CORE Blockchain. This is a self-executing software 
                                        algorithm that performs the function of distributing partner rewards between community 
                                        members, subject to certain conditions (Level and matrix marketing plan). 
                                        Information about transactions in the 
                                        CORE Blockchain can always be viewed at the link https://scan.coredao.org/
                                    </p>
                                    <div class="sub-heading">
                                        <span>POWER Of CORE</span>
                                    </div>
                                    <p>
                                        It’s been more than 2 years since the most well-known cryptocurrency CORE was launched. 
                                        Now there is a multitude of digital tokens available for traders like you and me to choose from.
                                    </p>
                                </div>
                            </div> 
                        </div>
                    </div>

                    {/* <!-----Core---Blockchain---------------------------------> */}

                    <div class="back-grid-short">                                 
                        <div class="heading">
                            <span>CORE Blockchain</span>
                            <div class="sub-heading">
                                <span>Advantages Of</span>
                            </div>
                        </div>
                    </div>
                    <div class="back-grid">
                        <div class="dash-triple">
                            <div class="card-triple">
                                <div class="card-logo">            
                                    <span><img src={Completely} alt=""/></span>
                                </div>    
                                <div class="sub-heading">
                                    <span>CORE is Completely</span>
                                </div>
                                <p>
                                    Core World is completely decentralized smart contract 
                                    works on self-execution of technical 
                                    functionalities with no admin interference.
                                </p>    
                            </div>
                            <div class="card-triple">
                                <div class="card-logo">            
                                    <span><img src={Complete} alt=""/></span>
                                </div>
                                <div class="sub-heading">
                                    <span>The Complete</span>
                                </div>
                                <p>
                                    The complete marketing strategy is represented by a 
                                    verified smart contract enlisted on the CORE Blockchain, 
                                    which is immutable and can't be changed or altered.
                                </p>
                            </div>
                            <div class="card-triple">
                                <div class="card-logo">            
                                    <span><img src={core_1} alt=""/></span>
                                </div>
                                <div class="sub-heading">
                                    <span>Low Gas Fee</span>
                                </div>
                                <p>
                                    CORE has very low transactions fee. In fact, 
                                    they are almost non-existent whenever you 
                                    make a transaction, you have to 
                                    pay minimum 0.00063 CORE and that’s basically it.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* <!-----Financial---Freedom--------------------------------> */}
                    <div class="back-grid-short">                                 
                        <div class="heading">
                            <span>Financial Freedom</span>
                            <div class="sub-heading">
                                <span>Features Of</span>
                            </div>
                        </div>
                    </div>

                    <div class="back-grid">
                        <div class="dash-double">
                            <div class="card-double-blank">
                                <div class="card-double">
                                    <div>
                                        <span><img src={freedom} alt=""/></span>
                                    </div>
                                </div>
                                <div class="card-double">    
                                    <div class="sub-heading">
                                        <span>Financial Freedom</span>
                                    </div>
                                    <p>
                                        An easy and fastest earning opportunity with the world’s first unique designed 
                                        Global, 12 Level income plan in a decentralized smart contract on the blockchain. 
                                    </p>
                                    <p>
                                        Core World is a self-executing smart contract on the blockchain, 
                                        it's a crowdfunding and network marketing system with a unique strategy. 
                                        Core World distributes 100% fund with an exciting Globaly affiliate programme, 
                                        2X Matrix & 12 Level Plan.
                                    </p>
                                    <div class="sub-heading">
                                        <span>Join</span>
                                    </div>
                                    <p>
                                        Sign Up and Become a part of Core World Smart Contract. 
                                    </p>
                                </div>
                            </div>    
                        </div>
                    </div>

                    {/* <!-----Faq---Section----------------------------------------------> */}
                    <div class="back-grid">
                        <section>
                            <h1 class="faq-title">Frequently Asked Questions</h1>
                            <div class="faq-heading">
                                <span>General</span>
                            </div>
                            <div class="quetions-box">
                                <div class="hide-space"></div>
                                <div class="question">
                                    <button id='faq_1_btn' onClick={() => {Btn_Click("faq_1_btn");}}>
                                        <span>Who Manage The Platform ?</span>
                                        <span>
                                            <img class="click-img" src={faq_1} alt=""/>
                                        </span>
                                    </button>
                                    <p>Core World does not have a Manager. This means that the platform is fully decentralized (i.e. it has no leaders or admins).</p>
                                </div>
                                <div class="question">
                                    <button id='faq_2_btn' onClick={() => {Btn_Click("faq_2_btn");}}>
                                        <span>What is Decentralization ?</span>
                                        <span>
                                            <img class="click-img" src={faq_1} alt=""/>
                                        </span>
                                    </button>
                                    <p>
                                        It is a system in which there are no admins, there is no single server or system monitoring, project management. The creators of the platform are the same project participants like you.
                                    </p>
                                </div>
                                <div class="question">
                                    <button id='faq_3_btn' onClick={() => {Btn_Click("faq_3_btn");}}>
                                        <span>What is Core ?</span>
                                        <span>
                                            <img class="click-img" src={faq_1}alt=""/>
                                        </span>
                                    </button>
                                    <p>CORE is one of the leading cryptocurrency, which has existed since 2022 At the same time, it is a software framework for L1 (Fully Decentralized Blockchain). 
                                        Since the blockchain of this crypto currency allows you not only to follow the history of transactions, but also to save any executable software products (smart contracts). 
                                        A huge number of major crypto companies use this platform.
                                    </p>
                                </div>
                                <div class="question">
                                    <button id='faq_4_btn' onClick={() => {Btn_Click("faq_4_btn");}}>
                                        <span>What is Smart Contract ?</span>
                                        <span>
                                            <img class="click-img" src={faq_1} alt=""/>
                                        </span>
                                    </button>
                                    <p>A Smart Contract is a computer software that manages the transfer of digital assets between a peer-to-peer network of involved parties directly and automatically under specific conditions.</p>
                                </div>
                                <div class="question">
                                    <button id='faq_5_btn' onClick={() => {Btn_Click("faq_5_btn");}}>
                                        <span>How Much Can You Earn ?</span>
                                        <span>
                                            <img class="click-img" src={faq_1} alt=""/>
                                        </span>
                                    </button>
                                    <p>Core World does not have a Manager. This means that the platform is fully decentralized. Which mean you can earn unlimited income under specific conditions.</p>
                                </div>
                            </div>
                        </section> 
                    </div>

                    {/* <!-----Smart----Contracts------------------------------> */}
                    <div class="back-grid-short">                                 
                        <div class="heading">
                            <span>Smart Contract Address</span>
                            <div class="sub-heading">
                                <span>Features Of</span>
                            </div>
                        </div>
                    </div>
                    <div class="back-grid-short">
                        <div class="smart-grid">
                            <div class="table scroll-bar">
                                <table>
                                    <thead>
                                    <tr>
                                        <td>Sr</td>
                                        <td>Core World</td>
                                        <td>Contract Address</td>
                                        <td>Link</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>FS_Main</td>
                                        <td>{FS_Main.address}</td>
                                        <td>
                                            <div style={{cursor: "pointer"}} onClick={() => {Check_Add(FS_Main.address, props.showAlert);}}>
                                                <a class="check">Check</a>
                                            </div>  
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* <!-----Footer----Section--------------------------------> */}

                    {/* <div class="back-grid-short">
                        <div class="dash-card-footer-main"> 
                            <div class="card-logo-footer">
                                <span><img src={Logo__3} alt=""/></span>
                            </div> 
                            <div class="link-column flex">
                                <span>Our Links</span>
                                <a href="#" class="hover-link">Home</a>
                                <a href="#" class="hover-link">Features</a>
                                <a href="#" class="hover-link">About Us</a>
                                <a href="#" class="hover-link">Advantage Of Core</a>
                                <a href="#" class="hover-link">Financial freedom</a>
                            </div>
                            <div class="link-column flex">
                                <span></span>
                                <span></span>
                            </div>  
                        </div>
                    </div> */}

                    {/* <!-----Sub-Footer----Section--------------------------------> */}

                    <div class="back-grid-short">
                        <div class="dash-sub-footer">
                            <div class="card-sub-footer">
                                <div class="copy-right">
                                    <span>© 2024 Core-World.</span>
                                </div>
                            </div>
                            <div class="card-sub-footer">
                                <div class="social-link">
                                    <span><img src={s_m_1} alt=""/></span>
                                    <span><img src={s_m_1} alt=""/></span>
                                    <span><img src={s_m_1} alt=""/></span>
                                    <span><img src={s_m_1} alt=""/></span>
                                </div>                         
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        {/* <script>
            const button = document.querySelectorAll("button");
            button.forEach( btn => {
                btn.addEventListener( 'click', ()=>{
                    const ans = btn.nextElementSibling;
                    const span2 = btn.children[1];
                    const item1 = span2.children[0];
                    
                    ans.classList.toggle('show');
                    item1.classList.toggle('rotate');
                    span2.classList.toggle
                })
            })
        </script> */}
        </>
    );
}
