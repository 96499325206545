import React, { useState, useEffect } from 'react';
import { createPublicClient, http, formatEther, formatUnits, createWalletClient, custom } from 'viem';
import { coreDao } from 'viem/chains';
import { privateKeyToAccount } from 'viem/accounts';

import "./backend_core_x.css";
import "./backend_core_x_media-queries.css";

import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react';

import { Test_CoreDao } from './Core_Test_Network';

import loading_gif from './images/loading_gif.gif';

//import USDT_Con from './contracts/TestUsdt_And_DateTime/TestUSDT_Token.json';
//import USDTDai_Con from './contracts/TestUsdt_And_DateTime/Test_DaiToken.json';

import CoreWorld_FS_Main_Con from './contracts/CoreWorld/CoreWorld_FS_Main.json';
import CoreWorld_Pack_Activation_Con from './contracts/CoreWorld/CoreWorld_Pack_Activation.json';


import Tick from "./assets/Tick.png";
import core_1 from './assets/core_1.png';
import Tick_Blank from './assets/Tick_Blank.png';
  
import core_3 from "./assets/core_3.png";

import right_arrow from "./assets/right_arrow.png";
import Arrow__ from "./assets/Arrow__.png";
import cross from "./assets/cross.png";

// const USDT = {
//     address: "0xd7454466794700Ed75ca06CA83eEaF25B85a2A1B",
//     abi: USDT_Con.abi
// }
export const FS_Main = {
    //address: "0xC943c59FbF9bE35F7a674a8564b82760eE5F3D63",
    address: "0x6B438485820f88D550bA5B183AeeE3ef8e02d7fC",
    abi: CoreWorld_FS_Main_Con.abi
}
const Pack_Activation =  {
    //address: "0x0736C11534d3F2f096e130C66bA82A115B633C95",
    address: "0x66874Ff19dF503112268bABb07c193D97A898710",
    abi: CoreWorld_Pack_Activation_Con.abi
}

//-------------------------------

const p_chain = coreDao;
//const p_chain = Test_CoreDao;

const p_transport = "https://rpc.coredao.org";
//const p_transport = "https://rpc.test.btcs.network";

const client = createPublicClient({
  chain: p_chain,
  transport: http(p_transport)
});

 
let USDT_unit = Number(6);
 
let USDT_deceimals = Number(2);
let Core_deceimals = Number(4);//2);

//let Symbol = "$";
let Symbol = "CORE";

let url = "https://coreworld.online/";
//let url = "http://localhost:3000/";

let type_url = "https://scan.coredao.org/address/";
//let type_url = "https://scan.test.btcs.network/address/";

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//-------------------------------------------For_Nutral-------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function Loading_Component(props) {

    return (
        <>
            <img style={{width: '30px', height: '30px'}} src={loading_gif} alt="loading" />
        </>
    );
}
function Card_LC(props) {

    return (
        <>
            <img style={{width: '15px', height: '15px'}} src={loading_gif} alt="loading" />
        </>
    );
}

//-----Search_Function_Area------------------

export async function Copy(data, showAlert) {
    if (data === '') {}
    else {
        navigator.clipboard.writeText(data);
        showAlert(data + " Copied To ClipBoard!...", 'success', true);
    }
}
export async function Check_Add(data, showAlert) {
    if (data === '') {}
    else {
        navigator.clipboard.writeText(type_url + "" + data);
        showAlert(type_url + "" + data + " Copied To ClipBoard!...", 'success', true);
    }
} 

export function Sub_Variable(data) {
    //console.log("_____________________");
    //console.log(data);
    if(data) {//(data !== "" || data !== undefined) {
        let var_data = data.substring(0, 10) + "......" + data.substring(31, 41);
        //console.log(var_data);
        return var_data;
    }
    else {
        return "";
    }
}
function Format_Usdt(data) {
    return Number(formatUnits(data, USDT_unit)).toFixed(USDT_deceimals);
}
function Format_Core(data) {
    return Number(formatEther(BigInt(data))).toFixed(Core_deceimals);
}
function IsSymbol() {
    return (
        <>
            {
                Symbol === "$" ? (
                    <>{Symbol}</>
                ) : (
                    <> {Symbol}</>
                )
            }
        </>
    )
}

async function Format_UTC(data) {
    //console.log(data);
    let dt_arr = new Date(Number(String(data) + String("000")));
    //console.log(dt_arr);
    let datetime = dt_arr.toLocaleString();
    //console.log(datetime);
    return datetime;
}

function Is_Value_Format(data) {
    return (
        <>
            {
                Symbol === "$" ? (
                    <>{Format_Usdt(data) + " " + Symbol}</>
                ) : (
                    <>{Format_Core(data) + " " + Symbol}</>
                )
            }
        </>
    );
} 
function Is_Value_Icon() {
    return (
        <>
            {
                Symbol === "$" ? (
                    <>{Symbol}</>
                ) : (
                    <>
                        <div class="c-logo-grid">
                            <span><img src={core_3} alt=""/></span>
                        </div>
                    </>
                )
            }
        </>
    );
}
function Is_Rank(pack_no) {
    return (
        <>
            {
                Number(pack_no) === Number(1) ? (<>{"Beginner"}</>) 
                : Number(pack_no) === Number(2) ? (<>{"Influncer"}</>) 
                : Number(pack_no) === Number(3) ? (<>{"Achiever"}</>) 

                : Number(pack_no) === Number(4) ? (<>{"Amber"}</>) 
                : Number(pack_no) === Number(5) ? (<>{"Pioneer"}</>) 
                : Number(pack_no) === Number(6) ? (<>{"Mentor"}</>) 

                : Number(pack_no) === Number(7) ? (<>{"Champion"}</>) 
                : Number(pack_no) === Number(8) ? (<>{"Director"}</>) 
                : Number(pack_no) === Number(9) ? (<>{"Titan"}</>) 

                : Number(pack_no) === Number(10) ? (<>{"Icon"}</>) 
                : Number(pack_no) === Number(11) ? (<>{"Legend"}</>) 
                : Number(pack_no) === Number(12) ? (<>{"Emperor"}</>) 
                : (<></>)
            }
        </>
    );
} 

/*async function Page_Settings(Total_No, SetTotal_Page, SetCurrent_Page) {
    try {
        let Total_No_Length = Number(String(Total_No).length);

        let Num = "";
        for(let i = 1; i <= Total_No_Length; i++) {
            if(Number(i) === Number(1)) {
                Num = "0";
            }   
            else if (Number(i) > Number(1)) {
                Num += String(Number(i-1));
            }
        }

        //console.log(" ");
        //console.log("----------------");
        //console.log(" ");
        //console.log(String(Total_No));
        //console.log(String(Num) + " Length : " + Total_No_Length);
        //console.log(" ");

        if(Number(Total_No) > 9) {
            //console.log(Total_No.toString()[1]); //0 = 1 1 = 0
            //console.log(Total_No.toString().charAt(0)); //0 = 1 1 = 0  
            //console.log(String(Total_No)[String(Total_No.length + 1)] + " +|+ " + String(Total_No)[1] ); //0 = 1 1 = 0  

            let Second_Last = Number(Total_No_Length-2);
            let First_Last = Number(Total_No_Length-1);
            let Total_Last = Number(String(Second_Last) + "" + String(First_Last));
            //console.log("Second_Last : " + Second_Last + " +|+ First_Last : " + First_Last);
            //console.log("Total_Last : " + Total_Last);     
            //console.log(" ");               

            let Second_Last_Value = String(Total_No)[Second_Last];
            let First_Last_Value = String(Total_No)[First_Last];
            let Total_Last_Value = Number(String(Second_Last_Value) + "" + String(First_Last_Value));
            //console.log("Second_Last Value : " + Second_Last_Value + " +|+ First_Last Value : " + First_Last_Value);
            //console.log("Total_Last_Value : " + Total_Last_Value);

            let TLV = Number(Total_Last_Value);

            let Second_Last_Plus_Value;
            if(Number(TLV) === Number(0) || Number(TLV) === Number(20) || Number(TLV) === Number(40) || Number(TLV) === Number(60) || Number(TLV) === Number(80) || Number(TLV) === Number(100)) {
                Second_Last_Plus_Value = 0;
            }
            else {
                if(Number(TLV) > Number(0) && Number(TLV) < Number(10)) { Second_Last_Plus_Value = String(1); }
                else if(Number(TLV) > Number(10) && Number(TLV) < Number(20)) { Second_Last_Plus_Value = String(0); }
                else if(Number(TLV) > Number(20) && Number(TLV) < Number(30)) { Second_Last_Plus_Value = String(1); }
                else if(Number(TLV) > Number(30) && Number(TLV) < Number(40)) { Second_Last_Plus_Value = String(0); }
                else if(Number(TLV) > Number(40) && Number(TLV) < Number(50)) { Second_Last_Plus_Value = String(1); }
                else if(Number(TLV) > Number(50) && Number(TLV) < Number(60)) { Second_Last_Plus_Value = String(0); }
                else if(Number(TLV) > Number(60) && Number(TLV) < Number(70)) { Second_Last_Plus_Value = String(1); }
                else if(Number(TLV) > Number(70) && Number(TLV) < Number(80)) { Second_Last_Plus_Value = String(0); }
                else if(Number(TLV) > Number(80) && Number(TLV) < Number(90)) { Second_Last_Plus_Value = String(1); }
                else if(Number(TLV) > Number(90) && Number(TLV) < Number(100)) { Second_Last_Plus_Value = String(0); }
            }

            let First_Last_Plus_Value;
            if(Number(First_Last_Value) === Number(0)) { First_Last_Plus_Value = String(0); }
            else if(Number(First_Last_Value) === Number(1)) { First_Last_Plus_Value = String(9); }
            else if(Number(First_Last_Value) === Number(2)) { First_Last_Plus_Value = String(8); }
            else if(Number(First_Last_Value) === Number(3)) { First_Last_Plus_Value = String(7); }
            else if(Number(First_Last_Value) === Number(4)) { First_Last_Plus_Value = String(6); }
            else if(Number(First_Last_Value) === Number(5)) { First_Last_Plus_Value = String(5); }
            else if(Number(First_Last_Value) === Number(6)) { First_Last_Plus_Value = String(4); }
            else if(Number(First_Last_Value) === Number(7)) { First_Last_Plus_Value = String(3); }
            else if(Number(First_Last_Value) === Number(8)) { First_Last_Plus_Value = String(2); }
            else if(Number(First_Last_Value) === Number(9)) { First_Last_Plus_Value = String(1); }

            let Total_Last_Plus_Value = Number(String(Second_Last_Plus_Value) + "" + String(First_Last_Plus_Value));

            //console.log(" ");
            
            //console.log("Second_Last_Plus_Value : " + Second_Last_Plus_Value + " +|+ First_Last_Plus_Value : " + First_Last_Plus_Value);
            //console.log("Total_Last_Plus_Value : " + Total_Last_Plus_Value);
            
            let Total_No_Updated = Number(Number(Total_No) + Number(Total_Last_Plus_Value));

            //console.log(" ");
            //console.log("Total_No_Updated : " + Total_No_Updated);

            let Total_Page = Number(Number(Total_No_Updated) / 20);
            //console.log(" ");
            //console.log("Total_Page : " + Total_Page);
            
            SetTotal_Page(Total_Page);
            SetCurrent_Page(Total_Page);
            
        }
        else if(Number(Total_No) < 10) {

            let First_Last = Number(Total_No_Length-1);
            //console.log("First_Last : " + First_Last);

            let First_Last_Value = String(Total_No)[First_Last];
            //console.log("First_Last Value : " + First_Last_Value);

            let First_Last_Plus_Value;

            if(Number(First_Last_Value) === Number(0)) { First_Last_Plus_Value = String(0); }
            else if(Number(First_Last_Value) === Number(1)) { First_Last_Plus_Value = String(9); }
            else if(Number(First_Last_Value) === Number(2)) { First_Last_Plus_Value = String(8); }
            else if(Number(First_Last_Value) === Number(3)) { First_Last_Plus_Value = String(7); }
            else if(Number(First_Last_Value) === Number(4)) { First_Last_Plus_Value = String(6); }
            else if(Number(First_Last_Value) === Number(5)) { First_Last_Plus_Value = String(5); }
            else if(Number(First_Last_Value) === Number(6)) { First_Last_Plus_Value = String(4); }
            else if(Number(First_Last_Value) === Number(7)) { First_Last_Plus_Value = String(3); }
            else if(Number(First_Last_Value) === Number(8)) { First_Last_Plus_Value = String(2); }
            else if(Number(First_Last_Value) === Number(9)) { First_Last_Plus_Value = String(1); }

            let Total_No_Updated;
            let Total_Page;
            if(Number(First_Last_Value) === Number(0)) {
                Total_No_Updated = Number(Number(Total_No) + Number(First_Last_Plus_Value) + Number(20));
                Total_Page = 1;
            }
            else if(Number(First_Last_Value) > Number(0)) {
                Total_No_Updated = Number(Number(Total_No) + Number(First_Last_Plus_Value) + Number(10));
                Total_Page = 1;
            }

            //console.log(" ");
            //console.log("Total_No_Updated : " + Total_No_Updated);
            //console.log(" ");
            //console.log("Total_Page : " + Total_Page);

            SetTotal_Page(Total_Page);
            SetCurrent_Page(Total_Page);

        }
    }
    catch(error) {
        console.log(error);
    }
}*/
async function Return_Page_Settings(Total_No) {
    try {
        let Total_No_Length = Number(String(Total_No).length);

        let Num = "";
        for(let i = 1; i <= Total_No_Length; i++) {
            if(Number(i) === Number(1)) {
                Num = "0";
            }   
            else if (Number(i) > Number(1)) {
                Num += String(Number(i-1));
            }
        }

        //console.log(" ");
        //console.log("----------------");
        //console.log(" ");
        //console.log(String(Total_No));
        //console.log(String(Num) + " Length : " + Total_No_Length);
        //console.log(" ");

        if(Number(Total_No) > 9) {
            //console.log(Total_No.toString()[1]); //0 = 1 1 = 0
            //console.log(Total_No.toString().charAt(0)); //0 = 1 1 = 0  
            //console.log(String(Total_No)[String(Total_No.length + 1)] + " +|+ " + String(Total_No)[1] ); //0 = 1 1 = 0  

            let Second_Last = Number(Total_No_Length-2);
            let First_Last = Number(Total_No_Length-1);
            let Total_Last = Number(String(Second_Last) + "" + String(First_Last));
            //console.log("Second_Last : " + Second_Last + " +|+ First_Last : " + First_Last);
            //console.log("Total_Last : " + Total_Last);     
            //console.log(" ");               

            let Second_Last_Value = String(Total_No)[Second_Last];
            let First_Last_Value = String(Total_No)[First_Last];
            let Total_Last_Value = Number(String(Second_Last_Value) + "" + String(First_Last_Value));
            //console.log("Second_Last Value : " + Second_Last_Value + " +|+ First_Last Value : " + First_Last_Value);
            //console.log("Total_Last_Value : " + Total_Last_Value);

            let TLV = Number(Total_Last_Value);

            let Second_Last_Plus_Value;
            if(Number(TLV) === Number(0) || Number(TLV) === Number(20) || Number(TLV) === Number(40) || Number(TLV) === Number(60) || Number(TLV) === Number(80) || Number(TLV) === Number(100)) {
                Second_Last_Plus_Value = 0;
            }
            if(Number(TLV) === Number(10) || Number(TLV) === Number(30) || Number(TLV) === Number(50) || Number(TLV) === Number(70) || Number(TLV) === Number(90)) {
                Second_Last_Plus_Value = 1;
            }
            else {
                if     (Number(TLV) > Number(0) && Number(TLV) < Number(10)) { Second_Last_Plus_Value = String(1); }
                else if(Number(TLV) > Number(10) && Number(TLV) < Number(20)) { Second_Last_Plus_Value = String(0); }
                else if(Number(TLV) > Number(20) && Number(TLV) < Number(30)) { Second_Last_Plus_Value = String(1); }
                else if(Number(TLV) > Number(30) && Number(TLV) < Number(40)) { Second_Last_Plus_Value = String(0); }
                else if(Number(TLV) > Number(40) && Number(TLV) < Number(50)) { Second_Last_Plus_Value = String(1); }
                else if(Number(TLV) > Number(50) && Number(TLV) < Number(60)) { Second_Last_Plus_Value = String(0); }
                else if(Number(TLV) > Number(60) && Number(TLV) < Number(70)) { Second_Last_Plus_Value = String(1); }
                else if(Number(TLV) > Number(70) && Number(TLV) < Number(80)) { Second_Last_Plus_Value = String(0); }
                else if(Number(TLV) > Number(80) && Number(TLV) < Number(90)) { Second_Last_Plus_Value = String(1); }
                else if(Number(TLV) > Number(90) && Number(TLV) < Number(100)) { Second_Last_Plus_Value = String(0); }
            }

            let First_Last_Plus_Value;
            if(Number(First_Last_Value) === Number(0)) { First_Last_Plus_Value = String(0); }
            else if(Number(First_Last_Value) === Number(1)) { First_Last_Plus_Value = String(9); }
            else if(Number(First_Last_Value) === Number(2)) { First_Last_Plus_Value = String(8); }
            else if(Number(First_Last_Value) === Number(3)) { First_Last_Plus_Value = String(7); }
            else if(Number(First_Last_Value) === Number(4)) { First_Last_Plus_Value = String(6); }
            else if(Number(First_Last_Value) === Number(5)) { First_Last_Plus_Value = String(5); }
            else if(Number(First_Last_Value) === Number(6)) { First_Last_Plus_Value = String(4); }
            else if(Number(First_Last_Value) === Number(7)) { First_Last_Plus_Value = String(3); }
            else if(Number(First_Last_Value) === Number(8)) { First_Last_Plus_Value = String(2); }
            else if(Number(First_Last_Value) === Number(9)) { First_Last_Plus_Value = String(1); }

            let Total_Last_Plus_Value = Number(String(Second_Last_Plus_Value) + "" + String(First_Last_Plus_Value));

            //console.log(" ");
            
            //console.log("Second_Last_Plus_Value : " + Second_Last_Plus_Value + " +|+ First_Last_Plus_Value : " + First_Last_Plus_Value);
            //console.log("Total_Last_Plus_Value : " + Total_Last_Plus_Value);
            
            let Total_No_Updated = Number(Number(Total_No) + Number(Total_Last_Plus_Value));

            //console.log(" ");
            //console.log("Total_No_Updated : " + Total_No_Updated);

            let Total_Page = Number(Number(Total_No_Updated) / 20);
            //console.log(" ");
            //console.log("Total_Page : " + Total_Page);
            
            return Total_Page;
            
        }
        else if(Number(Total_No) < 10) {

            let First_Last = Number(Total_No_Length-1);
            //console.log("First_Last : " + First_Last);

            let First_Last_Value = String(Total_No)[First_Last];
            //console.log("First_Last Value : " + First_Last_Value);

            let First_Last_Plus_Value;

            if(Number(First_Last_Value) === Number(0)) { First_Last_Plus_Value = String(0); }
            else if(Number(First_Last_Value) === Number(1)) { First_Last_Plus_Value = String(9); }
            else if(Number(First_Last_Value) === Number(2)) { First_Last_Plus_Value = String(8); }
            else if(Number(First_Last_Value) === Number(3)) { First_Last_Plus_Value = String(7); }
            else if(Number(First_Last_Value) === Number(4)) { First_Last_Plus_Value = String(6); }
            else if(Number(First_Last_Value) === Number(5)) { First_Last_Plus_Value = String(5); }
            else if(Number(First_Last_Value) === Number(6)) { First_Last_Plus_Value = String(4); }
            else if(Number(First_Last_Value) === Number(7)) { First_Last_Plus_Value = String(3); }
            else if(Number(First_Last_Value) === Number(8)) { First_Last_Plus_Value = String(2); }
            else if(Number(First_Last_Value) === Number(9)) { First_Last_Plus_Value = String(1); }

            let Total_No_Updated;
            let Total_Page;
            if(Number(First_Last_Value) === Number(0)) {
                Total_No_Updated = Number(Number(Total_No) + Number(First_Last_Plus_Value) + Number(20));
                Total_Page = 1;
            }
            else if(Number(First_Last_Value) > Number(0)) {
                Total_No_Updated = Number(Number(Total_No) + Number(First_Last_Plus_Value) + Number(10));
                Total_Page = 1;
            }

            //console.log(" ");
            //console.log("Total_No_Updated : " + Total_No_Updated);
            //console.log(" ");
            //console.log("Total_Page : " + Total_Page);

            return Total_Page;

        }
    }
    catch(error) {
        console.log(error);
    }
}

//-------------After-Login-----------------

//----Dashboard--------

export function Dashboard_Form(props) {
    
    const {address} = useAccount();
    //const address = "0x15A178931eAd5e2fe6F33C7917cb1cc3E00ec1a9";

    const[Should, SetShould] = useState(true);

    return (
        <>
            <div class="cards">
                {/* <!---------Current----Package-----------------> */}
                <Package_Detail address={address} SetLoadingModal={props.SetLoadingModal} showAlert={props.showAlert} Should={Should} SetShould={SetShould}/>
                {/* <!---------Rank----Income-----------------> */}
                <Rank_Income_Detail address={address} Should={Should}/>
                {/* <!-------------My-----Details-----------------> */}
                <My_Detail address={address} showAlert={props.showAlert} Should={Should}/>
                {/* <!---------Income----Details-----------------> */}
                <Income_Detail address={address} Should={Should}/>
            </div> 
        </>
    );
}

function Package_Detail(props) {

    const [Pack1_Img_Src, SetPack1_Img_Src] = useState(Tick_Blank);
    const [Pack2_Img_Src, SetPack2_Img_Src] = useState(Tick_Blank);
    const [Pack3_Img_Src, SetPack3_Img_Src] = useState(Tick_Blank);
    const [Pack4_Img_Src, SetPack4_Img_Src] = useState(Tick_Blank);
    const [Pack5_Img_Src, SetPack5_Img_Src] = useState(Tick_Blank);
    const [Pack6_Img_Src, SetPack6_Img_Src] = useState(Tick_Blank);
    const [Pack7_Img_Src, SetPack7_Img_Src] = useState(Tick_Blank);
    const [Pack8_Img_Src, SetPack8_Img_Src] = useState(Tick_Blank);
    const [Pack9_Img_Src, SetPack9_Img_Src] = useState(Tick_Blank);
    const [Pack10_Img_Src, SetPack10_Img_Src] = useState(Tick_Blank);
    const [Pack11_Img_Src, SetPack11_Img_Src] = useState(Tick_Blank);
    const [Pack12_Img_Src, SetPack12_Img_Src] = useState(Tick_Blank);

    const [Pack1_IsActivated, SetPack1_IsActivated] = useState(true);
    const [Pack2_IsActivated, SetPack2_IsActivated] = useState(true);
    const [Pack3_IsActivated, SetPack3_IsActivated] = useState(true);
    const [Pack4_IsActivated, SetPack4_IsActivated] = useState(true);
    const [Pack5_IsActivated, SetPack5_IsActivated] = useState(true);
    const [Pack6_IsActivated, SetPack6_IsActivated] = useState(true);
    const [Pack7_IsActivated, SetPack7_IsActivated] = useState(true);
    const [Pack8_IsActivated, SetPack8_IsActivated] = useState(true);
    const [Pack9_IsActivated, SetPack9_IsActivated] = useState(true);
    const [Pack10_IsActivated, SetPack10_IsActivated] = useState(true);
    const [Pack11_IsActivated, SetPack11_IsActivated] = useState(true);
    const [Pack12_IsActivated, SetPack12_IsActivated] = useState(true);

    const Activated_Package = async(pack_no) => {
        try {
            props.SetLoadingModal(true, true);
            if (window.ethereum) {
                // Delcalre a Wallet Client
                const walletClient = createWalletClient({
                    account: props.address,
                    chain: p_chain,
                    transport: custom(window.ethereum)
                });

                //console.log("WalletClient");
                //console.log(walletClient);

                let request_writeContract;

                
                let Core_Amount = []; //----10 CORE   //---18-Decimals---- Means 1 CORE
            
                Core_Amount[Number(1)] = 10000000000000000000; //----10 CORE   //---18-Decimals---- Means 1 CORE
                Core_Amount[Number(2)] = 20000000000000000000; //----20 CORE   //---18-Decimals---- Means 1 CORE
                Core_Amount[Number(3)] = 30000000000000000000; //----30 CORE   //---18-Decimals---- Means 1 CORE
                Core_Amount[Number(4)] = 40000000000000000000; //----40 CORE   //---18-Decimals---- Means 1 CORE
                Core_Amount[Number(5)] = 50000000000000000000; //----50 CORE   //---18-Decimals---- Means 1 CORE
                Core_Amount[Number(6)] = 60000000000000000000; //----60 CORE   //---18-Decimals---- Means 1 CORE
                Core_Amount[Number(7)] = 70000000000000000000; //----70 CORE   //---18-Decimals---- Means 1 CORE
                Core_Amount[Number(8)] = 80000000000000000000; //----80 CORE   //---18-Decimals---- Means 1 CORE
                Core_Amount[Number(9)] =  90000000000000000000; //----90 CORE   //---18-Decimals---- Means 1 CORE
                Core_Amount[Number(10)] = 100000000000000000000; //----100 CORE   //---18-Decimals---- Means 1 CORE
                Core_Amount[Number(11)] = 110000000000000000000; //----110 CORE   //---18-Decimals---- Means 1 CORE
                Core_Amount[Number(12)] = 120000000000000000000; //----120 CORE   //---18-Decimals---- Means 1 CORE
            

                props.SetLoadingModal(true, false);
                request_writeContract = await walletClient.writeContract({
                    address: Pack_Activation.address,
                    abi: Pack_Activation.abi,
                    functionName: "New_Add",
                    args: [props.address, pack_no],
                    value: BigInt(Core_Amount[pack_no])
                });
                props.SetLoadingModal(true, true);
            
 
                //console.log(request_writeContract);
                
                const transaction = await client.waitForTransactionReceipt({confirmations: 5, hash: request_writeContract});
                //console.log("Transaction is : ");
                //console.log(transaction);
                if (transaction.status === "success") {
                    props.SetLoadingModal(false, false);
                    props.showAlert("Package " + pack_no + " SuccessFully Activated !...", "success", true);
                    setTimeout(() => {
                        if(props.Should === true) {
                            props.SetShould(false);
                        }
                        else if(props.Should === false) {
                            props.SetShould(true);                        
                        }
                    }, [4000]);
                }
                else {
                    props.SetLoadingModal(false, false);
                    props.showAlert("Your Transaction Is Reverted", "danger", true);
                }
            }
            else {
                props.SetLoadingModal(false, false);
                props.showAlert("Please SignUp Through DAPPS!...", "warning", false);
            }
        }
        catch(error) {
            console.log(error);
            props.SetLoadingModal(false, false);
        }
    }

    useEffect(() => {
        const Load_Pack = async() => {

            SetPack1_Img_Src(Tick_Blank);
            SetPack1_IsActivated(true);
    
            SetPack2_Img_Src(Tick_Blank);
            SetPack2_IsActivated(true);
            
            SetPack3_Img_Src(Tick_Blank);
            SetPack3_IsActivated(true);
            
            SetPack4_Img_Src(Tick_Blank);
            SetPack4_IsActivated(true);
            
            SetPack5_Img_Src(Tick_Blank);
            SetPack5_IsActivated(true);
            
            SetPack6_Img_Src(Tick_Blank);
            SetPack6_IsActivated(true);
            
            SetPack7_Img_Src(Tick_Blank);
            SetPack7_IsActivated(true);
            
            SetPack8_Img_Src(Tick_Blank);
            SetPack8_IsActivated(true);
            
            SetPack9_Img_Src(Tick_Blank);
            SetPack9_IsActivated(true);
            
            SetPack10_Img_Src(Tick_Blank);
            SetPack10_IsActivated(true);
            
            SetPack11_Img_Src(Tick_Blank);
            SetPack11_IsActivated(true);
            
            SetPack12_Img_Src(Tick_Blank);
            SetPack12_IsActivated(true);
    
            const Arr = [];
            Arr.push({
                ImgSrc: Tick,
                IsActivated: false//true
            });
            let oneis = false;
            for(let i = 1; i <= 12; i++) {
    
                let Add_Pack_Exist = await client.readContract({
                    address: Pack_Activation.address,
                    abi: Pack_Activation.abi,
                    functionName: "GetAdd_Pack_Exist",
                    args: [props.address, Number(i)]
                });
    
                if(Add_Pack_Exist === true) {
                    Arr.push({
                        ImgSrc: Tick,
                        IsActivated: true
                    });
                }
                else if(Add_Pack_Exist === false) {
                    let isAct;
                    if(Number(i-1) === Number(0)) {
                        isAct = false;
                    }
                    else {
                        isAct = false;
                        //console.log((i-1) + " " + Arr[(i-1)].IsActivated);
                        if(oneis === false) {
                            if(Arr[(i-1)].IsActivated === false) {
                                isAct = true;
                                oneis = true
                            }
                        }
                        else if(oneis === true) {
                            isAct = true;
                        }    
                    }
                    
    
                    Arr.push({
                        ImgSrc: Tick_Blank,
                        IsActivated: isAct
                    });
                }
            }
    
            //console.log(Arr);
            
            SetPack1_Img_Src(Arr[1].ImgSrc);
            SetPack1_IsActivated(Arr[1].IsActivated);
    
            SetPack2_Img_Src(Arr[2].ImgSrc);
            SetPack2_IsActivated(Arr[2].IsActivated);
    
            SetPack3_Img_Src(Arr[3].ImgSrc);
            SetPack3_IsActivated(Arr[3].IsActivated);
            
            SetPack4_Img_Src(Arr[4].ImgSrc);
            SetPack4_IsActivated(Arr[4].IsActivated);
            
            SetPack5_Img_Src(Arr[5].ImgSrc);
            SetPack5_IsActivated(Arr[5].IsActivated);
            
            SetPack6_Img_Src(Arr[6].ImgSrc);
            SetPack6_IsActivated(Arr[6].IsActivated);
            
            SetPack7_Img_Src(Arr[7].ImgSrc);
            SetPack7_IsActivated(Arr[7].IsActivated);
            
            SetPack8_Img_Src(Arr[8].ImgSrc);
            SetPack8_IsActivated(Arr[8].IsActivated);
            
            SetPack9_Img_Src(Arr[9].ImgSrc);
            SetPack9_IsActivated(Arr[9].IsActivated);
            
            SetPack10_Img_Src(Arr[10].ImgSrc);
            SetPack10_IsActivated(Arr[10].IsActivated);
            
            SetPack11_Img_Src(Arr[11].ImgSrc);
            SetPack11_IsActivated(Arr[11].IsActivated);
            
            SetPack12_Img_Src(Arr[12].ImgSrc);
            SetPack12_IsActivated(Arr[12].IsActivated);
        }
        if(props.address) {
            Load_Pack();
        }
    }, [props.address, props.Should]);

    return (
        <>  
            <div class="card-check">
                <div class="card-single-header">
                    <span>Packages</span>
                </div>
                <div class="card-check-gap">
                    <span class="slot-n">Beginner</span>
                    <span class="slot-type"><img src={Pack1_Img_Src} alt=""/></span>
                    <span class="slot-amount">10</span>
                    <span class="c-tikker-logo"><img src={core_1} alt=""/></span>
                    <span class="slot-activation"><button disabled={Pack1_IsActivated} onClick={() => {Activated_Package(Number(1));}}>ACTIVATE</button></span>                       
                </div>
                <div class="card-check-gap">
                    <span class="slot-n">Influncer</span>
                    <span class="slot-type"><img src={Pack2_Img_Src} alt=""/></span>
                    <span class="slot-amount">20</span>
                    <span class="c-tikker-logo"><img src={core_1} alt=""/></span>
                    <span class="slot-activation"><button disabled={Pack2_IsActivated} onClick={() => {Activated_Package(Number(2));}}>ACTIVATE</button></span>                       
                </div>
                <div class="card-check-gap">
                    <span class="slot-n">Achiever</span>
                    <span class="slot-type"><img src={Pack3_Img_Src} alt=""/></span>
                    <span class="slot-amount">30</span>
                    <span class="c-tikker-logo"><img src={core_1} alt=""/></span>
                    <span class="slot-activation"><button disabled={Pack3_IsActivated} onClick={() => {Activated_Package(Number(3));}}>ACTIVATE</button></span>                       
                </div>
                <div class="card-check-gap">
                    <span class="slot-n">Amber</span>
                    <span class="slot-type"><img src={Pack4_Img_Src} alt=""/></span>
                    <span class="slot-amount">40</span>
                    <span class="c-tikker-logo"><img src={core_1} alt=""/></span>
                    <span class="slot-activation"><button disabled={Pack4_IsActivated} onClick={() => {Activated_Package(Number(4));}}>ACTIVATE</button></span>                  
                </div>
                <div class="card-check-gap">
                    <span class="slot-n">Pioneer</span>
                    <span class="slot-type"><img src={Pack5_Img_Src} alt=""/></span>
                    <span class="slot-amount">50</span>
                    <span class="c-tikker-logo"><img src={core_1} alt=""/></span>
                    <span class="slot-activation"><button disabled={Pack5_IsActivated} onClick={() => {Activated_Package(Number(5));}}>ACTIVATE</button></span>                  
                </div>
                <div class="card-check-gap">
                    <span class="slot-n">Mentor</span>
                    <span class="slot-type"><img src={Pack6_Img_Src} alt=""/></span>
                    <span class="slot-amount">60</span>
                    <span class="c-tikker-logo"><img src={core_1} alt=""/></span>
                    <span class="slot-activation"><button disabled={Pack6_IsActivated} onClick={() => {Activated_Package(Number(6));}}>ACTIVATE</button></span>                  
                </div>

                <div class="card-check-gap">
                    <span class="slot-n">Champion</span>
                    <span class="slot-type"><img src={Pack7_Img_Src} alt=""/></span>
                    <span class="slot-amount">70</span>
                    <span class="c-tikker-logo"><img src={core_1} alt=""/></span>
                    <span class="slot-activation"><button disabled={Pack7_IsActivated} onClick={() => {Activated_Package(Number(7));}}>ACTIVATE</button></span>                  
                </div>
                <div class="card-check-gap">
                    <span class="slot-n">Director</span>
                    <span class="slot-type"><img src={Pack8_Img_Src} alt=""/></span>
                    <span class="slot-amount">80</span>
                    <span class="c-tikker-logo"><img src={core_1} alt=""/></span>
                    <span class="slot-activation"><button disabled={Pack8_IsActivated} onClick={() => {Activated_Package(Number(8));}}>ACTIVATE</button></span>                  
                </div>
                <div class="card-check-gap">
                    <span class="slot-n">Titan</span>
                    <span class="slot-type"><img src={Pack9_Img_Src} alt=""/></span>
                    <span class="slot-amount">90</span>
                    <span class="c-tikker-logo"><img src={core_1} alt=""/></span>
                    <span class="slot-activation"><button disabled={Pack9_IsActivated} onClick={() => {Activated_Package(Number(9));}}>ACTIVATE</button></span>                  
                </div>
                <div class="card-check-gap">
                    <span class="slot-n">Icon</span>
                    <span class="slot-type"><img src={Pack10_Img_Src} alt=""/></span>
                    <span class="slot-amount">100</span>
                    <span class="c-tikker-logo"><img src={core_1} alt=""/></span>
                    <span class="slot-activation"><button disabled={Pack10_IsActivated} onClick={() => {Activated_Package(Number(10));}}>ACTIVATE</button></span>                  
                </div>
                <div class="card-check-gap">
                    <span class="slot-n">Legend</span>
                    <span class="slot-type"><img src={Pack11_Img_Src} alt=""/></span>
                    <span class="slot-amount">110</span>
                    <span class="c-tikker-logo"><img src={core_1} alt=""/></span>
                    <span class="slot-activation"><button disabled={Pack11_IsActivated} onClick={() => {Activated_Package(Number(11));}}>ACTIVATE</button></span>                  
                </div>
                <div class="card-check-gap">
                    <span class="slot-n">Emperor</span>
                    <span class="slot-type"><img src={Pack12_Img_Src} alt=""/></span>
                    <span class="slot-amount">120</span>
                    <span class="c-tikker-logo"><img src={core_1} alt=""/></span>
                    <span class="slot-activation"><button disabled={Pack12_IsActivated} onClick={() => {Activated_Package(Number(12));}}>ACTIVATE</button></span>                  
                </div>
            </div>
        </>
    );
}
function Rank_Income_Detail(props) {
    
    const[Pack1_Income, SetPack1_Income] = useState("");
    const[Pack2_Income, SetPack2_Income] = useState("");
    const[Pack3_Income, SetPack3_Income] = useState("");
    const[Pack4_Income, SetPack4_Income] = useState("");
    const[Pack5_Income, SetPack5_Income] = useState("");
    const[Pack6_Income, SetPack6_Income] = useState("");
    const[Pack7_Income, SetPack7_Income] = useState("");
    const[Pack8_Income, SetPack8_Income] = useState("");
    const[Pack9_Income, SetPack9_Income] = useState("");
    const[Pack10_Income, SetPack10_Income] = useState("");
    const[Pack11_Income, SetPack11_Income] = useState("");
    const[Pack12_Income, SetPack12_Income] = useState("");

    useEffect(() => {
        const Load = async() => {
            try {
                //GetUpid_50_Pack_Total_Reward (pack, add);
                let LC = Card_LC();
                SetPack1_Income(LC);
                SetPack2_Income(LC);
                SetPack3_Income(LC);
                SetPack4_Income(LC);
                SetPack5_Income(LC);
                SetPack6_Income(LC);
                SetPack7_Income(LC);
                SetPack8_Income(LC);
                SetPack9_Income(LC);
                SetPack10_Income(LC);
                SetPack11_Income(LC);
                SetPack12_Income(LC);

                const Arr = [];
                for(let i = 1; i <= 12; i++) {
                    let total_Spo_Lvl = BigInt(0);
                    for(let lvl = 1; lvl <= 3; lvl++) {
                        let Spo_Pack_Lvl_Total_Reward = await client.readContract({
                            address: Pack_Activation.address,
                            abi: Pack_Activation.abi,
                            functionName: "GetSpo_Pack_Lvl_Total_Reward",
                            args: [props.address, Number(i), Number(lvl)]
                        });
                        total_Spo_Lvl += BigInt(Spo_Pack_Lvl_Total_Reward);
                    }
                
                    let Upid_50_Pack_Total_Reward = await client.readContract({
                        address: Pack_Activation.address,
                        abi: Pack_Activation.abi,
                        functionName: "GetUpid_50_Pack_Total_Reward",
                        args: [props.address, Number(i)]
                    });
                    let Total_Rank_Income = (total_Spo_Lvl + Upid_50_Pack_Total_Reward);
                    
                    //Arr[i] = ("A" + (i).toString());
                    Arr[i] = (Format_Core(Total_Rank_Income));//Is_Value_Format(Total_Rank_Income));
                }
                //console.log(Arr);
                SetPack1_Income(Arr[1]);
                SetPack2_Income(Arr[2]);
                SetPack3_Income(Arr[3]);
                SetPack4_Income(Arr[4]);
                SetPack5_Income(Arr[5]);
                SetPack6_Income(Arr[6]);
                SetPack7_Income(Arr[7]);
                SetPack8_Income(Arr[8]);
                SetPack9_Income(Arr[9]);
                SetPack10_Income(Arr[10]);
                SetPack11_Income(Arr[11]);
                SetPack12_Income(Arr[12]);
            }
            catch(error) {
                console.log(error);
                SetPack1_Income("");
                SetPack2_Income("");
                SetPack3_Income("");
                SetPack4_Income("");
                SetPack5_Income("");
                SetPack6_Income("");
                SetPack7_Income("");
                SetPack8_Income("");
                SetPack9_Income("");
                SetPack10_Income("");
                SetPack11_Income("");
                SetPack12_Income("");
            }
        }
        if(props.address) {
            Load();
        }
    }, [props.address, props.Should]);

    return (
        <>
            <div class="card-rank-income">
                <div class="rank-card-gap">
                    <span class="rank-r">Rank</span>
                    <span class="rank-h">Income</span>
                </div>
                <div class="rank-card-gap">
                    <span class="rank">Beginner</span>
                    <span class="rank-i">{Pack1_Income}</span>
                    <span class="c-icon">CORE</span>                      
                </div>
                <div class="rank-card-gap">
                    <span class="rank">Influncer</span>
                    <span class="rank-i">{Pack2_Income}</span>
                    <span class="c-icon">CORE</span>                       
                </div>
                <div class="rank-card-gap">
                    <span class="rank">Achiever</span>
                    <span class="rank-i">{Pack3_Income}</span>
                    <span class="c-icon">CORE</span>                       
                </div>
                <div class="rank-card-gap">
                    <span class="rank">Amber</span>
                    <span class="rank-i">{Pack4_Income}</span>
                    <span class="c-icon">CORE</span>                       
                </div>
                <div class="rank-card-gap">
                    <span class="rank">Pioneer</span>
                    <span class="rank-i">{Pack5_Income}</span>
                    <span class="c-icon">CORE</span>                       
                </div>
                <div class="rank-card-gap">
                    <span class="rank">Mentor</span>
                    <span class="rank-i">{Pack6_Income}</span>
                    <span class="c-icon">CORE</span>                       
                </div>
                <div class="rank-card-gap">
                    <span class="rank">Champion</span>
                    <span class="rank-i">{Pack7_Income}</span>
                    <span class="c-icon">CORE</span>                       
                </div>
                <div class="rank-card-gap">
                    <span class="rank">Director</span>
                    <span class="rank-i">{Pack8_Income}</span>
                    <span class="c-icon">CORE</span>                       
                </div>
                <div class="rank-card-gap">
                    <span class="rank">Titan</span>
                    <span class="rank-i">{Pack9_Income}</span>
                    <span class="c-icon">CORE</span>                       
                </div>
                <div class="rank-card-gap">
                    <span class="rank">Icon</span>
                    <span class="rank-i">{Pack10_Income}</span>
                    <span class="c-icon">CORE</span>                       
                </div>
                <div class="rank-card-gap">
                    <span class="rank">Legend</span>
                    <span class="rank-i">{Pack11_Income}</span>
                    <span class="c-icon">CORE</span>                       
                </div>
                <div class="rank-card-gap">
                    <span class="rank">Emperor</span>
                    <span class="rank-i">{Pack12_Income}</span>
                    <span class="c-icon">CORE</span>                       
                </div>
            </div>
        </>
    );
}
function My_Detail(props) {

    const [Current_Pack, SetCurrent_Pack] = useState(Number(0));
    const [ID, SetID] = useState("");
    const [Refferal_Link, SetRefferal_Link] = useState("");
    const [Refferal_ID, SetRefferal_ID] = useState("");
    const [Total_Fund, SetTotal_Fund] = useState("");

    useEffect(() => {
        const Load = async() => {
            try {
                let LC = Card_LC();
                SetCurrent_Pack(LC);
                SetRefferal_Link(LC);
                SetID(LC);
                SetRefferal_ID(LC);
                SetTotal_Fund(LC);

                let Cur_Pack = await client.readContract({
                    address: Pack_Activation.address,
                    abi: Pack_Activation.abi,
                    functionName: "GetAdd_Current_PackNo",
                    args: [props.address]
                });
                let User_ID = await client.readContract({
                    address: FS_Main.address,
                    abi: FS_Main.abi,
                    functionName: "GetUser_Address_ID",
                    args: [props.address]
                });
                let User_Add_Spo = await client.readContract({
                    address: FS_Main.address,
                    abi: FS_Main.abi,
                    functionName: "GetUser_Add_Spo",
                    args: [props.address]
                });
                let Spo_ID = await client.readContract({
                    address: FS_Main.address,
                    abi: FS_Main.abi,
                    functionName: "GetUser_Address_ID",
                    args: [User_Add_Spo]
                });

                //console.log(Cur_Pack);
                //console.log(User_ID);
                //console.log(url + "r/" + User_ID);
                //console.log(Spo_ID);

                SetCurrent_Pack(Cur_Pack);
                SetID(User_ID);
                SetRefferal_Link(url + "r/" + User_ID);
                SetRefferal_ID(Spo_ID);

                // if(Symbol === "$") {
                //     let User_Usdt_BalanceOf = await client.readContract({
                //         address: USDT.address,
                //         abi: USDT.abi,
                //         functionName: "balanceOf",
                //         args: [props.address]
                //     });
                //     SetTotal_Fund(Format_Usdt(User_Usdt_BalanceOf) + " $");
                // }
                // else {
                    let User_Core_BalanceOf = await client.getBalance({address: props.address});
                    SetTotal_Fund(Format_Core(User_Core_BalanceOf));
                // }

            }
            catch(error) {
                console.log(error);
                SetCurrent_Pack(Number(0));
                SetID("");
                SetRefferal_ID("");
                SetTotal_Fund("");
            }
        }
        if(props.address) {
            Load();
        }
    }, [props.address, props.Should]);

    return (
        <>
            <div class="card-single">
                <div class="card-single-header">
                    <span>Details</span>
                </div>
                <div class="card-income-header">
                    <h3>My_Refferal_link</h3>
                </div>
                <div class="r-link">                   
                    <span>{Refferal_Link}</span>
                </div>
                <div class="copy-link">
                    <button class="btn" onClick={() => {Copy(Refferal_Link, props.showAlert);}}>
                        <div>Copy</div>
                    </button> 
                </div>
                <div class="card-line-header">
                    <div>Refferal_ID</div>                      
                    <div class="sp-id">
                        <span>{Refferal_ID}</span>
                    </div> 
                </div>
                <div class="card-line-header">
                    <div>My_ID</div>                      
                    <div class="my-id">
                        <span>{ID}</span>
                    </div> 
                </div>
                <div class="card-line-header">
                    <div>My_Wallet</div>                      
                    <div class="my-w">
                    <span>{Sub_Variable(props.address)}</span>
                </div> 
                </div>
                <div class="card-line-header">
                    <div>My_Rank</div>                      
                    <div class="my-rank">
                    <span>{Is_Rank(Current_Pack)}</span>
                </div> 
                </div>
                <div class="card-line-header">                      
                    <div class="c-logo">
                        <span><img src={core_1} alt=""/></span>
                    </div> 
                </div>
                <div class="card-line-header">
                    <div class="c-name">My_Wallet_Fund // CORE</div>                  
                    <div class="c-value">
                    <span>{Total_Fund}</span>
                </div> 
                </div>
            </div>
        </>
    );
}
function Income_Detail(props) {

    const [Current_Pack, SetCurrent_Pack] = useState(Number(0));

    const [Total_Income, SetTotal_Income] = useState("");
    const [Refferal_Income, SetRefferal_Income] = useState("");
    const [Level_Income, SetLevel_Income] = useState("");
    const [My_Direct_Refferal, SetMy_Direct_Refferal] = useState("");
    const [My_Community_Team, SetMy_Community_Team] = useState("");
    const [Total_Fund, SetTotal_Fund] = useState("");

    useEffect(() => {
        const Load = async() => {
            try {

                let LC = Card_LC();
                
                SetCurrent_Pack(Number(0));

                SetTotal_Income(LC);
                SetRefferal_Income(LC);
                SetLevel_Income(LC);
                SetMy_Direct_Refferal(LC);
                SetMy_Community_Team(LC);
                SetTotal_Fund(LC);

                let Cur_Pack = await client.readContract({
                    address: Pack_Activation.address,
                    abi: Pack_Activation.abi,
                    functionName: "GetAdd_Current_PackNo",
                    args: [props.address]
                });

                let RR_Add_Total_Reward = await client.readContract({
                    address: Pack_Activation.address,
                    abi: Pack_Activation.abi,
                    functionName: "GetRR_Add_Total_Reward",
                    args: [props.address]
                });

                let Spo_Income = Number(0);
                for(let tpack_no = 1; tpack_no <= Number(Cur_Pack); tpack_no++) {
                    for(let i = 1; i <= 3; i++) {
                        let Spo_Pack_Lvl_Total_Reward = await client.readContract({
                            address: Pack_Activation.address,
                            abi: Pack_Activation.abi,
                            functionName: "GetSpo_Pack_Lvl_Total_Reward",
                            args: [props.address, Number(tpack_no), Number(i)]
                        });
                        Spo_Income += Number(Spo_Pack_Lvl_Total_Reward);
                    }
                }

                let Total_SpoNode = await client.readContract({
                    address: FS_Main.address,
                    abi: FS_Main.abi,
                    functionName: "GetTotal_SpoNode",
                    args: [props.address]
                });

                let Total_Lvl = Number(0);
                let Total_Lvl_Income = Number(0);

                for(let i = 1; i <= 12; i++) {
                    let DSG_UpidNode = await client.readContract({
                        address: FS_Main.address,
                        abi: FS_Main.abi,
                        functionName: "GetDSG_UpidNode",
                        args: [props.address, Number(i)]
                    });
                    let Upid_50_Pack_Lvl_Total_Reward = await client.readContract({
                        address: Pack_Activation.address,
                        abi: Pack_Activation.abi,
                        functionName: "GetUpid_50_Pack_Lvl_Total_Reward",
                        args: [props.address, Number(i), Number(i)]
                    })
                    Total_Lvl += Number(DSG_UpidNode);
                    Total_Lvl_Income += Number(Upid_50_Pack_Lvl_Total_Reward);
                }

                //console.log("RR_Add_Total_Reward : " + RR_Add_Total_Reward);
                //console.log("Spo_Income : " + Spo_Income);
                //console.log("Total_Lvl_Income : " + Total_Lvl_Income);

                //console.log("Total_SpoNode : " + Total_SpoNode);
                //console.log("Total_Lvl : " + Total_Lvl);
                

                SetCurrent_Pack(Number(Cur_Pack));

                SetTotal_Income(Is_Value_Format(RR_Add_Total_Reward));
                SetRefferal_Income(Is_Value_Format(Spo_Income));
                SetLevel_Income(Is_Value_Format(Total_Lvl_Income));

                SetMy_Direct_Refferal(Number(Total_SpoNode));
                SetMy_Community_Team(Number(Total_Lvl));

                // if(Symbol === "$") {
                //     let User_Usdt_BalanceOf = await client.readContract({
                //         address: USDT.address,
                //         abi: USDT.abi,
                //         functionName: "balanceOf",
                //         args: [props.address]
                //     });
                //     SetTotal_Fund(Format_Usdt(User_Usdt_BalanceOf) + " $");
                // }
                //else {
                    let User_Core_BalanceOf = await client.getBalance({address: props.address});
                    SetTotal_Fund(Format_Core(User_Core_BalanceOf));
                //}

            }
            catch(error) {
                console.log(error);
                SetCurrent_Pack(Number(0));
            
                SetTotal_Income("");
                SetRefferal_Income("");
                SetLevel_Income("");
                SetMy_Direct_Refferal("");
                SetMy_Community_Team("");
                SetTotal_Fund("");
            }
        }
        if(props.address) {
            Load();
        }
    }, [props.address, props.Should]);

    return (
        <>
            <div class="card-single">
                <div class="card-single-header">
                    <span>Income Details</span>
                </div>
                <div class="card-income-header">
                    <h3>Total_Income</h3>                      
                    <div class="total-income">
                        <span>{Total_Income}</span>
                    </div> 
                </div>
                <div class="card-line-header">
                    <div>Refferal_Income</div>                      
                    <div class="reffer-income">
                        <span>{Refferal_Income}</span>
                    </div> 
                </div>
                <div class="card-line-header">
                    <div>Level_Income</div>                      
                    <div class="level-income">
                    <span>{Level_Income}</span>
                </div> 
                </div> 
                <div class="card-line-header">
                    <div>My_Direct_Refferals</div>                      
                    <div class="direct-reffer">
                        <span>{My_Direct_Refferal}</span>
                    </div> 
                </div>
                <div class="card-line-header">
                    <div>My_Community_Team</div>                      
                    <div class="community-team">
                    <span>{My_Community_Team}</span>
                </div> 
                </div>
                <div class="card-line-header">
                    <div>My_Rank</div>                      
                    <div class="my-rank">
                    <span>{Is_Rank(Current_Pack)}</span>
                </div> 
                </div>
                <div class="card-line-header">                      
                    <div class="c-logo">
                        <span><img src={core_1} alt=""/></span>
                    </div> 
                </div>
                <div class="card-line-header">
                    <div class="c-name">My_Wallet_Fund // CORE</div>                  
                    <div class="c-value">
                    <span>{Total_Fund}</span>
                </div> 
                </div>
            </div>
        </>
    );
}

//----Community--------

export function Community_Form(props) {
 
    const {address} = useAccount();
    //const address = "0x15A178931eAd5e2fe6F33C7917cb1cc3E00ec1a9";

    const[TN0_Img, SetTN0_Img] = useState(cross);
    const[TN0_ID, SetTN0_ID] = useState("");
    const[TN0_Is, SetTN0_Is] = useState("Vacant");

    const[TN1_Img, SetTN1_Img] = useState(cross);
    const[TN1_ID, SetTN1_ID] = useState("");
    const[TN1_Is, SetTN1_Is] = useState("Vacant");
    
    const[TN2_Img, SetTN2_Img] = useState(cross);
    const[TN2_ID, SetTN2_ID] = useState("");
    const[TN2_Is, SetTN2_Is] = useState("Vacant");
    
    const[TN3_Img, SetTN3_Img] = useState(cross);
    const[TN3_ID, SetTN3_ID] = useState("");
    const[TN3_Is, SetTN3_Is] = useState("Vacant");
    
    const[TN4_Img, SetTN4_Img] = useState(cross);
    const[TN4_ID, SetTN4_ID] = useState("");
    const[TN4_Is, SetTN4_Is] = useState("Vacant");
    
    const[TN5_Img, SetTN5_Img] = useState(cross);
    const[TN5_ID, SetTN5_ID] = useState("");
    const[TN5_Is, SetTN5_Is] = useState("Vacant");
    
    const[TN6_Img, SetTN6_Img] = useState(cross);
    const[TN6_ID, SetTN6_ID] = useState("");
    const[TN6_Is, SetTN6_Is] = useState("Vacant");

    const Tree_Structure = async(id) => {
        try {

            SetTN0_Img(loading_gif);
            SetTN1_Img(loading_gif);
            SetTN2_Img(loading_gif);
            SetTN3_Img(loading_gif);
            SetTN4_Img(loading_gif);
            SetTN5_Img(loading_gif);
            SetTN6_Img(loading_gif);

            let tn0_add = await client.readContract({
                address: FS_Main.address,
                abi: FS_Main.abi,
                functionName: "GetUser_ID_Address",
                args: [id]
            });

            let tn0_add_exist = await client.readContract({
                address: Pack_Activation.address,
                abi: Pack_Activation.abi,
                functionName: "GetAdd_Pack_Exist",
                args: [tn0_add, Number(1)]
            });

            if(tn0_add_exist === true) {
                SetTN0_Img(core_1);
                SetTN0_ID(id);
                SetTN0_Is("Tree");

                SetTN1_Img(loading_gif);
                SetTN2_Img(loading_gif);

                let TN1_Add = await client.readContract({
                    address: FS_Main.address,
                    abi: FS_Main.abi,
                    functionName: "GetDSG_Add_Through_UpidNode",
                    args: [tn0_add, Number(1), Number(1)]
                });
                let TN2_Add = await client.readContract({
                    address: FS_Main.address,
                    abi: FS_Main.abi,
                    functionName: "GetDSG_Add_Through_UpidNode",
                    args: [tn0_add, Number(1), Number(2)]
                });
                
                if(TN1_Add !== "0x0000000000000000000000000000000000000000") {
                    let TN1id = await client.readContract({
                        address: FS_Main.address,
                        abi: FS_Main.abi,
                        functionName: "GetUser_Address_ID",
                        args: [TN1_Add]
                    });
                    SetTN1_Img(core_1);
                    SetTN1_ID(TN1id);
                    SetTN1_Is("Tree");
                    
                    SetTN3_Img(loading_gif);
                    SetTN4_Img(loading_gif);

                    let TN3_Add = await client.readContract({
                        address: FS_Main.address,
                        abi: FS_Main.abi,
                        functionName: "GetDSG_Add_Through_UpidNode",
                        args: [TN1_Add, Number(1), Number(1)]
                    });
                    let TN4_Add = await client.readContract({
                        address: FS_Main.address,
                        abi: FS_Main.abi,
                        functionName: "GetDSG_Add_Through_UpidNode",
                        args: [TN1_Add, Number(1), Number(2)]
                    });

                    if(TN3_Add !== "0x0000000000000000000000000000000000000000") {
                        let TN3id = await client.readContract({
                            address: FS_Main.address,
                            abi: FS_Main.abi,
                            functionName: "GetUser_Address_ID",
                            args: [TN3_Add]
                        });
                        SetTN3_Img(core_1);
                        SetTN3_ID(TN3id);
                        SetTN3_Is("Tree");
                    }
                    else {
                        SetTN3_Img(cross);
                        SetTN3_ID("");
                        SetTN3_Is("Vacant");
                    }

                    if(TN4_Add !== "0x0000000000000000000000000000000000000000") {
                        let TN4id = await client.readContract({
                            address: FS_Main.address,
                            abi: FS_Main.abi,
                            functionName: "GetUser_Address_ID",
                            args: [TN4_Add]
                        });
                        SetTN4_Img(core_1);
                        SetTN4_ID(TN4id);
                        SetTN4_Is("Tree");
                    }
                    else {
                        SetTN4_Img(cross);
                        SetTN4_ID("");
                        SetTN4_Is("Vacant");
                    }
                }
                else {
                    SetTN1_Img(cross);
                    SetTN1_ID("");
                    SetTN1_Is("Vacant");

                    SetTN3_Img(cross);
                    SetTN3_ID("");
                    SetTN3_Is("Vacant");
                    
                    SetTN4_Img(cross);
                    SetTN4_ID("");
                    SetTN4_Is("Vacant");
                }

                if(TN2_Add !== "0x0000000000000000000000000000000000000000") {
                    let TN2id = await client.readContract({
                        address: FS_Main.address,
                        abi: FS_Main.abi,
                        functionName: "GetUser_Address_ID",
                        args: [TN2_Add]
                    });
                    SetTN2_Img(core_1);
                    SetTN2_ID(TN2id);
                    SetTN2_Is("Tree");
                    
                    SetTN5_Img(loading_gif);
                    SetTN6_Img(loading_gif);

                    let TN5_Add = await client.readContract({
                        address: FS_Main.address,
                        abi: FS_Main.abi,
                        functionName: "GetDSG_Add_Through_UpidNode",
                        args: [TN2_Add, Number(1), Number(1)]
                    });
                    let TN6_Add = await client.readContract({
                        address: FS_Main.address,
                        abi: FS_Main.abi,
                        functionName: "GetDSG_Add_Through_UpidNode",
                        args: [TN2_Add, Number(1), Number(2)]
                    });

                    if(TN5_Add !== "0x0000000000000000000000000000000000000000") {
                        let TN5id = await client.readContract({
                            address: FS_Main.address,
                            abi: FS_Main.abi,
                            functionName: "GetUser_Address_ID",
                            args: [TN5_Add]
                        });
                        SetTN5_Img(core_1);
                        SetTN5_ID(TN5id);
                        SetTN5_Is("Tree");
                    }
                    else {
                        SetTN5_Img(cross);
                        SetTN5_ID("");
                        SetTN5_Is("Vacant");
                    }

                    if(TN6_Add !== "0x0000000000000000000000000000000000000000") {
                        let TN6id = await client.readContract({
                            address: FS_Main.address,
                            abi: FS_Main.abi,
                            functionName: "GetUser_Address_ID",
                            args: [TN6_Add]
                        });
                        SetTN6_Img(core_1);
                        SetTN6_ID(TN6id);
                        SetTN6_Is("Tree");
                    }
                    else {
                        SetTN6_Img(cross);
                        SetTN6_ID("");
                        SetTN6_Is("Vacant");
                    }
                }
                else {
                    SetTN2_Img(cross);
                    SetTN2_ID("");
                    SetTN2_Is("Vacant");

                    SetTN5_Img(cross);
                    SetTN5_ID("");
                    SetTN5_Is("Vacant");
                    
                    SetTN6_Img(cross);
                    SetTN6_ID("");
                    SetTN6_Is("Vacant");
                }

            }
            else {
                SetTN0_Img(cross);
                SetTN0_ID("");
                SetTN0_Is("Vacant");
            
                SetTN1_Img(cross);
                SetTN1_ID("");
                SetTN1_Is("Vacant");
                
                SetTN2_Img(cross);
                SetTN2_ID("");
                SetTN2_Is("Vacant");
                
                SetTN3_Img(cross);
                SetTN3_ID("");
                SetTN3_Is("Vacant");
                
                SetTN4_Img(cross);
                SetTN4_ID("");
                SetTN4_Is("Vacant");
                
                SetTN5_Img(cross);
                SetTN5_ID("");
                SetTN5_Is("Vacant");
                
                SetTN6_Img(cross);
                SetTN6_ID("");
                SetTN6_Is("Vacant");
            }
            
        }
        catch(error) {
            console.log(error);
            SetTN0_Img(cross);
            SetTN0_ID("");
            SetTN0_Is("Vacant");
        
            SetTN1_Img(cross);
            SetTN1_ID("");
            SetTN1_Is("Vacant");
            
            SetTN2_Img(cross);
            SetTN2_ID("");
            SetTN2_Is("Vacant");
            
            SetTN3_Img(cross);
            SetTN3_ID("");
            SetTN3_Is("Vacant");
            
            SetTN4_Img(cross);
            SetTN4_ID("");
            SetTN4_Is("Vacant");
            
            SetTN5_Img(cross);
            SetTN5_ID("");
            SetTN5_Is("Vacant");
            
            SetTN6_Img(cross);
            SetTN6_ID("");
            SetTN6_Is("Vacant");
        }
    }

    const My_ID = async() => {
        try {
            let Id = await client.readContract({
                address: FS_Main.address,
                abi: FS_Main.abi,
                functionName: "GetUser_Address_ID",
                args: [address]
            });
            Tree_Structure(Id);
        }
        catch(error) {
            console.log(error);
        }
    }
    const Search = async() => {
        try {

            let search_id_input = document.getElementById("search_id_input").value;
            
            let search_add = await client.readContract({
                address: FS_Main.address,
                abi: FS_Main.abi,
                functionName: "GetUser_ID_Address",
                args: [search_id_input]
            });

            let add_exist = await client.readContract({
                address: Pack_Activation.address,
                abi: Pack_Activation.abi,
                functionName: "GetAdd_Pack_Exist",
                args: [search_add, Number(1)]
            });
            
            if(add_exist === true) {
                let DSG_Add = await client.readContract({
                    address: FS_Main.address, 
                    abi: FS_Main.abi,
                    functionName: "GetDSG_Add",
                    args: [search_add]
                });

                //console.log(DSG_Add[2]);

                let should = false;
                for(let i = 1; i <= 25; i++) {
                    if(Number(i) < Number(25)) {
                        //console.log("i : " + i + " DSG_Add[2][" +i+ "] : " + DSG_Add[2][i] + " address : " + address);
                        if(DSG_Add[2][i] === address) {
                            should = true;
                            break;
                        }
                    }
                    else if (Number(i) === Number(25)) {
                        should = false;
                        break;
                    }                    
                }
                
                if(should === true) {
                    let Id__ = await client.readContract({
                        address: FS_Main.address,
                        abi: FS_Main.abi,
                        functionName: "GetUser_Address_ID",
                        args: [search_add]
                    });
                    Tree_Structure(Id__);
                }
                else if (should === false) {
                    props.showAlert("ID Doesn't Exist In Your Tree", "danger", true);
                }
            }
            else {
                props.showAlert("Doesn't Exist", "danger", true);
            }
        }
        catch(error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if(address) {
            My_ID();
        }
    }, [address]);

    return (
        <>
            <div class="hide-space"></div>
            <div class="back-grid-short">                                 
                <div class="heading">
                    <span>Community</span>
                </div>
            </div>
            <div class="back-grid">
                <div class="back-grid-short-tree"> 
                    <div class="back-grid-tree-blank">
                        <span></span>
                    </div>
                    <div class="back-grid-tree-id-search">
                        <div class="card-search">
                            <div class="search">
                                <input type="text" id='search_id_input' name="search" placeholder="Search here"/>
                                <label for="search">
                                </label>
                            </div> 
                            <div class="search-id">
                                <button class="myid" onClick={() => {My_ID();}}>
                                    <div>My ID</div>
                                </button>
                                <button class="team-id-search" onClick={() => {Search();}}>
                                    <div>Search</div>
                            </button>    
                            </div>
                        </div> 
                    </div>
                    <div class="back-grid-tree-blank">
                        <span></span>
                    </div>
                </div>
                <div class="back-grid-short-tree"> 
                    <div class="back-grid-tree-blank">
                        <span></span>
                    </div>
                    <div class="back-grid-tree-id">
                        <div class="tree-img"><img src={TN0_Img} alt=""/></div>
                        <div class="tree-id">{TN0_ID}</div>
                        <div class="tree-id-btn"><button disabled={true}>{TN0_Is}</button></div> 
                    </div>
                    <div class="back-grid-tree-blank">
                        <span></span>
                    </div>
                </div>
                <div class="back-grid-short-tree"> 
                    <div class="back-grid-tree-blank">
                        <span></span>
                    </div>
                    <div class="back-grid-tree-id">
                        <div class="tree-img"><img src={TN1_Img} alt=""/></div>
                        <div class="tree-id">{TN1_ID}</div>
                        <div class="tree-id-btn"><button disabled={TN1_Is==="Vacant"} onClick={() => {Tree_Structure(TN1_ID);}}>{TN1_Is}</button></div> 
                    </div>
                    <div class="back-grid-tree-blank">
                        <span></span>
                    </div>
                    <div class="back-grid-tree-id">
                        <div class="tree-img"><img src={TN2_Img} alt=""/></div>
                        <div class="tree-id">{TN2_ID}</div>
                        <div class="tree-id-btn"><button disabled={TN2_Is==="Vacant"} onClick={() => {Tree_Structure(TN2_ID);}}>{TN2_Is}</button></div> 
                    </div>
                    <div class="back-grid-tree-blank">
                        <span></span>
                    </div>
                </div>
                <div class="back-grid-short-tree">                               
                    <div class="back-grid-tree-blank">
                        <span></span>
                    </div>
                    <div class="back-grid-tree-id">
                        <div class="tree-img"><img src={TN3_Img} alt=""/></div>
                        <div class="tree-id">{TN3_ID}</div>
                        <div class="tree-id-btn"><button disabled={TN3_Is==="Vacant"} onClick={() => {Tree_Structure(TN3_ID);}}>{TN3_Is}</button></div> 
                    </div>
                    <div class="back-grid-tree-blank">
                        <span></span>
                    </div>
                    <div class="back-grid-tree-id">
                        <div class="tree-img"><img src={TN4_Img} alt=""/></div>
                        <div class="tree-id">{TN4_ID}</div>
                        <div class="tree-id-btn"><button disabled={TN4_Is==="Vacant"} onClick={() => {Tree_Structure(TN4_ID);}}>{TN4_Is}</button></div> 
                    </div>
                    <div class="back-grid-tree-blank">
                        <span></span>
                    </div>
                    <div class="back-grid-tree-id">
                        <div class="tree-img"><img src={TN5_Img} alt=""/></div>
                        <div class="tree-id">{TN5_ID}</div>
                        <div class="tree-id-btn"><button disabled={TN5_Is==="Vacant"} onClick={() => {Tree_Structure(TN5_ID);}}>{TN5_Is}</button></div> 
                    </div>
                    <div class="back-grid-tree-blank">
                        <span></span>
                    </div>
                    <div class="back-grid-tree-id">
                        <div class="tree-img"><img src={TN6_Img} alt=""/></div>
                        <div class="tree-id">{TN6_ID}</div>
                        <div class="tree-id-btn"><button disabled={TN6_Is==="Vacant"} onClick={() => {Tree_Structure(TN6_ID);}}>{TN6_Is}</button></div> 
                    </div>
                    <div class="back-grid-tree-blank">
                        <span></span>
                    </div>
                </div> 
            </div>
        </>
    );
}

//----Refferals--------

export function Refferals_Form(props) {

    const {address} = useAccount();
    //const address = "0x15A178931eAd5e2fe6F33C7917cb1cc3E00ec1a9";

    const [Total_Page, SetTotal_Page] = useState(Number(0));
    const [Current_Page, SetCurrent_Page] = useState(Number(0));

    const [Refferal_Is, SetRefferal_Id] = useState("")
    const [Refferal_List, SetRefferal_List] = useState([]);

    const Current_Page_Detail = async(cur_page) => {
        try {
            SetRefferal_Id(Loading_Component());

            let from = Number(Number(Number(cur_page) - Number(1)) * Number(20) + Number(1));
            let to = Number(Number(cur_page) * Number(20)); 
            //console.log(from + " from");
            //console.log(to + " to");

            SetRefferal_List([]);
            let Arr = [];
            for(let i = from; i <= to; i++) {

                let Spo_Detail = await client.readContract({
                    address: FS_Main.address,
                    abi: FS_Main.abi,
                    functionName: "Get_Spo_Detail",
                    args: [address, Number(i)]
                });

                if(Spo_Detail[2] !== "") {
                    let Add_Current_PackNo = await client.readContract({
                        address: Pack_Activation.address,
                        abi: Pack_Activation.abi,
                        functionName: "GetAdd_Current_PackNo",
                        args: [Spo_Detail[1]]
                    });
                    let rank_ = Is_Rank(Add_Current_PackNo);        

                    let income_ = Number(0);

                    let User_Personal_Cur_PackNo = await client.readContract({
                        address: Pack_Activation.address,
                        abi: Pack_Activation.abi,
                        functionName: "GetAdd_Current_PackNo",
                        args: [address]
                    });
                    //console.log("User_Personal_Cur_PackNo : " + Number(User_Personal_Cur_PackNo));

                    for(let tpack_no = 1; tpack_no <= Number(User_Personal_Cur_PackNo); tpack_no++) {
                        let Spo_Pack_Lvl_Node = await client.readContract({
                            address: Pack_Activation.address,
                            abi: Pack_Activation.abi,
                            functionName: "GetSpo_Pack_Lvl_Node",
                            args: [address, Number(tpack_no), Number(1)]
                        })
                        for(let sp = 1; sp <= Number(Spo_Pack_Lvl_Node); sp++) {
                            let Spo_Pack_Lvl_Node_Add = await client.readContract({
                                address: Pack_Activation.address,
                                abi: Pack_Activation.abi,
                                functionName: "GetSpo_Pack_Lvl_Node_Add",
                                args: [address, Number(tpack_no), Number(1), sp]
                            });

                            if(Spo_Pack_Lvl_Node_Add[1] === Spo_Detail[1]) {
                                income_ += Number(Spo_Pack_Lvl_Node_Add[2]);
                            }
                            else {
                                income_ += Number(0);
                            }
                        }
                    }

                    let team_ = await client.readContract({
                        address: FS_Main.address,
                        abi: FS_Main.abi,
                        functionName: "GetTotal_SpoNode",
                        args: [Spo_Detail[1]]
                    });  
                    
                    let User_Joining_TimeStamp = await client.readContract({
                        address: FS_Main.address,
                        abi: FS_Main.abi,
                        functionName: "GetUser_Joining_TimeStamp",
                        args: [Spo_Detail[1]]
                    });

                    //console.log(Number(User_Joining_TimeStamp));
                    let time_ = await Format_UTC(Number(User_Joining_TimeStamp));

                    Arr.push({
                        sr: i,
                        id: Spo_Detail[2],
                        add: Sub_Variable(Spo_Detail[1]),
                        rank: rank_,
                        income: Is_Value_Format(income_),
                        directed_by: Spo_Detail[2],
                        team: Number(team_),
                        time: time_
                    });
                }
                else {
                    break;
                }
            }
            SetRefferal_List(Arr);
            SetRefferal_Id("");
            //console.log(Refferal_List);
        }
        catch(error) {
            console.log(error);
            SetRefferal_List([]);
            SetRefferal_Id("");
        }
    }

    const Next = async() => {
        let nextpage = Number(Number(Current_Page) + Number(1));
        if(Number(nextpage) > Number(Total_Page)) {

        }
        else if(Number(nextpage) <= Number(Total_Page)) {
            SetCurrent_Page(nextpage);
            Current_Page_Detail(nextpage);
        }
    }
    const Pre = async() => {
        let prepage = Number(Number(Current_Page) - Number(1));
        if(Number(prepage) < Number(1)) {

        }
        else if(Number(prepage) >= Number(1)) {
            SetCurrent_Page(prepage);
            Current_Page_Detail(prepage);
        }
    }

    useEffect(() => {
        const First_Load = async() => {

            try {
                let Total_SpoNode = await client.readContract({
                    address: FS_Main.address,
                    abi: FS_Main.abi,
                    functionName: "GetTotal_SpoNode",
                    args: [address]
                });

                let cur_page = await Return_Page_Settings(Number(Total_SpoNode));
                //console.log(Number(cur_page));

                SetTotal_Page(Number(cur_page));
                SetCurrent_Page(Number(cur_page));
                Current_Page_Detail(Number(cur_page));
            }
            catch (error) {
                console.log(error);
                SetTotal_Page(Number(0));
                SetCurrent_Page(Number(0));
                Current_Page_Detail(0);
            }
        }
        
        if(address) {
            First_Load();
        }
    }, [address]);

    return (
        <>
            <div class="hide-space"></div>
            <div class="back-grid-short">                                 
                <div class="heading">
                    <span>Direct Refferals</span>
                    <div class="card-next-previous">
                        <span class="pre-btn"><button onClick={() => {Pre();}}>Pre</button></span>
                        <span>{Current_Page} / {Total_Page}</span>
                        <span class="next-btn"><button onClick={() => {Next();}}>Next</button></span>  
                    </div>
                </div>
            </div>
            <div class="back-grid">
                <div class="table scroll-bar">
                    
                    <table>
                        <thead>
                            <td>Sr</td>
                            <td>ID</td>
                            <td>Address</td>
                            <td>Rank</td>
                            <td>Income</td>
                            <td>Tikker</td>
                            <td>Direct By</td>
                            <td>Arrow</td>
                            <td>Team</td>
                            <td>Time</td>
                        </thead>
                        <tbody>
                            {
                                Refferal_Is === "" ? (
                                    <>
                                        {
                                            Refferal_List.map((data) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{data.sr}</td>
                                                            <td>{data.id}</td>
                                                            <td>{data.add}</td>
                                                            <td>{data.rank}</td>
                                                            <td>{data.income}</td>
                                                            <td>
                                                                <div class="c-logo-grid">
                                                                    <span><img src={core_3} alt=""/></span>
                                                                </div>
                                                            </td>
                                                            <td>{data.directed_by}</td>
                                                            <td>
                                                                <div class="arrow-logo-grid">
                                                                    <span><img src={Arrow__} alt=""/></span>
                                                                </div>
                                                            </td>
                                                            <td>{data.team}</td>
                                                            <td>{data.time}</td>
                                                        </tr>
                                                    </>
                                                );
                                            })
                                        }
                                    </>
                                ) : (
                                    <>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{Refferal_Is}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </>
                                )
                            }
                            {/*<tr>
                                <td>2</td>
                                <td>1</td>
                                <td>0x001111</td>
                                <td>Influancer</td>
                                <td>100</td>
                                <td>
                                    <div class="c-logo-grid">
                                        <span><img src={core_3} alt=""/></span>
                                    </div>
                                </td>
                                <td>1001</td>
                                <td>
                                    <div class="arrow-logo-grid">
                                        <span><img src={right_arrow} alt=""/></span>
                                    </div>
                                </td>
                                <td>10</td>
                                <td>2024-11-18 11:27:18 UTC</td>
                            </tr> 
                            <tr>
                                <td>3</td>
                                <td>1</td>
                                <td>0x001111</td>
                                <td>Achiever</td>
                                <td>100</td>
                                <td>
                                    <div class="c-logo-grid">
                                        <span><img src={core_3} alt=""/></span>
                                    </div>
                                </td>
                                <td>1001</td>
                                <td>
                                    <div class="arrow-logo-grid">
                                        <span><img src={right_arrow} alt=""/></span>
                                    </div>
                                </td>
                                <td>10</td>
                                <td>2024-11-18 11:27:18 UTC</td>
                            </tr> 
                            <tr>
                                <td>4</td>
                                <td>1</td>
                                <td>0x001111</td>
                                <td>Amber</td>
                                <td>100</td>
                                <td>
                                    <div class="c-logo-grid">
                                        <span><img src={core_3} alt=""/></span>
                                    </div>
                                </td>
                                <td>1001</td>
                                <td>
                                    <div class="arrow-logo-grid">
                                        <span><img src={right_arrow} alt=""/></span>
                                    </div>
                                </td>
                                <td>10</td>
                                <td>2024-11-18 11:27:18 UTC</td>
                            </tr> 
                            <tr>
                                <td>5</td>
                                <td>1</td>
                                <td>0x001111</td>
                                <td>Pioner</td>
                                <td>100</td>
                                <td>
                                    <div class="c-logo-grid">
                                        <span><img src={core_3} alt=""/></span>
                                    </div>
                                </td>
                                <td>1001</td>
                                <td>
                                    <div class="arrow-logo-grid">
                                        <span><img src={right_arrow} alt=""/></span>
                                    </div>
                                </td>
                                <td>10</td>
                                <td>2024-11-18 11:27:18 UTC</td>
                            </tr> 
                            <tr>
                                <td>6</td>
                                <td>1</td>
                                <td>0x001111</td>
                                <td>Mentor</td>
                                <td>100</td>
                                <td>
                                    <div class="c-logo-grid">
                                        <span><img src={core_3} alt=""/></span>
                                    </div>
                                </td>
                                <td>1001</td>
                                <td>
                                    <div class="arrow-logo-grid">
                                        <span><img src={right_arrow} alt=""/></span>
                                    </div>
                                </td>
                                <td>10</td>
                                <td>2024-11-18 11:27:18 UTC</td>
                            </tr> 
                            <tr>
                                <td>7</td>
                                <td>1</td>
                                <td>0x001111</td>
                                <td>Champion</td>
                                <td>100</td>
                                <td>
                                    <div class="c-logo-grid">
                                        <span><img src={core_3} alt=""/></span>
                                    </div>
                                </td>
                                <td>1001</td>
                                <td>
                                    <div class="arrow-logo-grid">
                                        <span><img src={right_arrow} alt=""/></span>
                                    </div>
                                </td>
                                <td>10</td>
                                <td>2024-11-18 11:27:18 UTC</td>
                            </tr>  
                            <tr>
                                <td>8</td>
                                <td>1</td>
                                <td>0x001111</td>
                                <td>Director</td>
                                <td>100</td>
                                <td>
                                    <div class="c-logo-grid">
                                        <span><img src={core_3} alt=""/></span>
                                    </div>
                                </td>
                                <td>1001</td>
                                <td>
                                    <div class="arrow-logo-grid">
                                        <span><img src={right_arrow} alt=""/></span>
                                    </div>
                                </td>
                                <td>10</td>
                                <td>2024-11-18 11:27:18 UTC</td>
                            </tr>  
                            <tr>
                                <td>9</td>
                                <td>1</td>
                                <td>0x001111</td>
                                <td>Titan</td>
                                <td>100</td>
                                <td>
                                    <div class="c-logo-grid">
                                        <span><img src={core_3} alt=""/></span>
                                    </div>
                                </td>
                                <td>1001</td>
                                <td>
                                    <div class="arrow-logo-grid">
                                        <span><img src={right_arrow} alt=""/></span>
                                    </div>
                                </td>
                                <td>10</td>
                                <td>2024-11-18 11:27:18 UTC</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>1</td>
                                <td>0x001111</td>
                                <td>Icon</td>
                                <td>100</td>
                                <td>
                                    <div class="c-logo-grid">
                                        <span><img src={core_3} alt=""/></span>
                                    </div>
                                </td>
                                <td>1001</td>
                                <td>
                                    <div class="arrow-logo-grid">
                                        <span><img src={right_arrow} alt=""/></span>
                                    </div>
                                </td>
                                <td>10</td>
                                <td>2024-11-18 11:27:18 UTC</td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>1</td>
                                <td>0x001111</td>
                                <td>Legend</td>
                                <td>100</td>
                                <td>
                                    <div class="c-logo-grid">
                                        <span><img src={core_3} alt=""/></span>
                                    </div>
                                </td>
                                <td>1001</td>
                                <td>
                                    <div class="arrow-logo-grid">
                                        <span><img src={right_arrow} alt=""/></span>
                                    </div>
                                </td>
                                <td>10</td>
                                <td>2024-11-18 11:27:18 UTC</td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>1</td>
                                <td>0x001111</td>
                                <td>Emperor</td>
                                <td>100</td>
                                <td>
                                    <div class="c-logo-grid">
                                        <span><img src={core_3} alt=""/></span>
                                    </div>
                                </td>
                                <td>1001</td>
                                <td>
                                    <div class="arrow-logo-grid">
                                        <span><img src={right_arrow} alt=""/></span>
                                    </div>
                                </td>
                                <td>10</td>
                                <td>2024-11-18 11:27:18 UTC</td>
                            </tr> */} 
                        </tbody>
                    </table>
                </div> 
            </div>
        </>
    );
}

//----Level_Downline--------

export function Level_Downline_Form(props) {

    const {address} = useAccount();
    // const address = "0x15A178931eAd5e2fe6F33C7917cb1cc3E00ec1a9";

    const [Current_Lvl, SetCurrent_Lvl] = useState(Number(0));
    const [Total_Page, SetTotal_Page] = useState(Number(0));
    const [Current_Page, SetCurrent_Page] = useState(Number(0));

    const [Level_Downline_Is, SetLevel_Downline_Is] = useState("");
    const [Level_Downline_Detail, SetLevel_Downline_Detail] = useState([]);

    const First_Lvl_Load = async(lvl) => {
        try {
            SetLevel_Downline_Is(Loading_Component());
            SetCurrent_Lvl(Number(lvl));
            let Upid_50_Pack_Node = await client.readContract({
                address: Pack_Activation.address,
                abi: Pack_Activation.abi,
                functionName: "GetUpid_50_Pack_Node",
                args: [address, Number(lvl), Number(lvl)]
            });

            let cur_page = await Return_Page_Settings(Number(Upid_50_Pack_Node));
            //console.log(Number(cur_page));

            SetTotal_Page(Number(cur_page));
            SetCurrent_Page(Number(cur_page));
            Current_Page_Detail(Number(cur_page), Number(lvl));
        }
        catch(error) {
            console.log(error);
            SetTotal_Page(Number(0));
            SetCurrent_Page(Number(0));
            Current_Page_Detail(Number(0));
            SetLevel_Downline_Is("");
        }
    }

    const Select_Lvl = async(lvl) => {
        SetCurrent_Lvl(lvl);
        First_Lvl_Load(lvl);
    }

    const Current_Page_Detail = async(cur_page, cur_lvl) => {
        try {
            SetLevel_Downline_Is(Loading_Component());

            let from = Number(Number(Number(cur_page) - Number(1)) * Number(20) + Number(1));
            let to = Number(Number(cur_page) * Number(20)); 
            //console.log(from + " from");
            //console.log(to + " to");

            SetLevel_Downline_Detail([]);
            let Arr = [];
            for(let i = from; i <= to; i++) {

                let Upid_50_Pack_Node_Add = await client.readContract({
                    address: Pack_Activation.address,
                    abi: Pack_Activation.abi,
                    functionName: "GetUpid_50_Pack_Node_Add",
                    args: [address, Number(cur_lvl), Number(cur_lvl), Number(i)]
                });

                //console.log("Upid_50_Pack_Node_Add[1] : " + Upid_50_Pack_Node_Add[1]);
                if(Upid_50_Pack_Node_Add[1] !== "0x0000000000000000000000000000000000000000") {

                    let id_ = await client.readContract({
                        address: FS_Main.address,
                        abi: FS_Main.abi,
                        functionName: "GetUser_Address_ID",
                        args: [Upid_50_Pack_Node_Add[1]]
                    });

                    let rank_ = Is_Rank(cur_lvl);  

                    let team_ = await client.readContract({
                        address: FS_Main.address,
                        abi: FS_Main.abi,
                        functionName: "GetTotal_SpoNode",
                        args: [Upid_50_Pack_Node_Add[1]]
                    });  

                    let time_ = await Format_UTC(Number(Upid_50_Pack_Node_Add[4]));

                    Arr.push({
                        sr: i,
                        id: id_,
                        add: Sub_Variable(Upid_50_Pack_Node_Add[1]),
                        rank: rank_,
                        income: Is_Value_Format(Upid_50_Pack_Node_Add[3]),
                        directed_by: id_,
                        team: Number(team_),
                        time: time_
                    });
                }
                else {
                    break;
                }
            }
            SetLevel_Downline_Detail(Arr);
            SetLevel_Downline_Is("");
            //console.log(Level_Downline_Detail);
        }
        catch(error) {
            console.log(error);
            SetLevel_Downline_Detail([]);
            SetLevel_Downline_Is("");
        }
    }

    const Next = async() => {
        let nextpage = Number(Number(Current_Page) + Number(1));
        if(Number(nextpage) > Number(Total_Page)) {

        }
        else if(Number(nextpage) <= Number(Total_Page)) {
            SetCurrent_Page(nextpage);
            Current_Page_Detail(nextpage, Number(Current_Lvl));
        }
    }
    const Pre = async() => {
        let prepage = Number(Number(Current_Page) - Number(1));
        if(Number(prepage) < Number(1)) {

        }
        else if(Number(prepage) >= Number(1)) {
            SetCurrent_Page(prepage);
            Current_Page_Detail(prepage, Number(Current_Lvl));
        }
    }

    useEffect(() => {
        if(address) {
            First_Lvl_Load(1);
        }
    }, [address]);

    return (
        <>
            <div class="hide-space"></div>
            <div class="back-grid-short">                                 
                <div class="heading">
                    <span>Level Downline</span>
                    <div class="back-grid-short-simple scroll-bar">
                        <div class="check-level">
                            <span><button class="click-level" onClick={() => {Select_Lvl(Number(1));}}>1</button></span>
                            <span><button class="click-level" onClick={() => {Select_Lvl(Number(2));}}>2</button></span>
                            <span><button class="click-level" onClick={() => {Select_Lvl(Number(3));}}>3</button></span>
                            <span><button class="click-level" onClick={() => {Select_Lvl(Number(4));}}>4</button></span>
                            <span><button class="click-level" onClick={() => {Select_Lvl(Number(5));}}>5</button></span>
                            <span><button class="click-level" onClick={() => {Select_Lvl(Number(6));}}>6</button></span>
                            <span><button class="click-level" onClick={() => {Select_Lvl(Number(7));}}>7</button></span>
                            <span><button class="click-level" onClick={() => {Select_Lvl(Number(8));}}>8</button></span>
                            <span><button class="click-level" onClick={() => {Select_Lvl(Number(9));}}>9</button></span>
                            <span><button class="click-level" onClick={() => {Select_Lvl(Number(10));}}>10</button></span>
                            <span><button class="click-level" onClick={() => {Select_Lvl(Number(11));}}>11</button></span>
                            <span><button class="click-level" onClick={() => {Select_Lvl(Number(12));}}>12</button></span>
                        </div>    
                    </div> 
                    <div class="card-next-previous">
                        <span class="pre-btn"><button onClick={() => {Pre();}}>Pre</button></span>
                        <span>{Current_Page} / {Total_Page}</span>
                        <span class="next-btn"><button onClick={() => {Next();}}>Next</button></span>
                    </div>
                </div>
            </div>
            <div class="back-grid">  
                <div class="table scroll-bar">
                    <table>                                                                                                                                                                                      
                        <thead>
                            <td>Sr</td>
                            <td>ID</td>
                            <td>Address</td>
                            <td>Rank</td>
                            <td>Income</td>
                            <td>TIKKER</td>
                            <td>Direct By</td>
                            <td>Arrow</td>
                            <td>Team</td>
                            <td>Time</td>
                        </thead>
                        <tbody>
                            {
                                Level_Downline_Is === "" ? (
                                    <>
                                        {
                                            Level_Downline_Detail.map((data) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{data.sr}</td>
                                                            <td>{data.id}</td>
                                                            <td>{data.add}</td>
                                                            <td>{data.rank}</td>
                                                            <td>{data.income}</td>
                                                            <td>
                                                                <div class="c-logo-grid">
                                                                    <span><img src={core_3} alt=""/></span>
                                                                </div>
                                                            </td>
                                                            <td>{data.directed_by}</td>
                                                            <td>
                                                                <div class="arrow-logo-grid">
                                                                    <span><img src={Arrow__} alt=""/></span>
                                                                </div>
                                                            </td>
                                                            <td>{data.team}</td>
                                                            <td>{data.time}</td>
                                                        </tr>  
                                                    </>
                                                );
                                            })
                                        }
                                    </>
                                ) : (
                                    <>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{Level_Downline_Is}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </>
                                )
                            }
                        </tbody>
                    </table>
                </div> 
            </div>
        </>
    );
}

//----My_Rank--------

export function My_Rank_Form(props) {

    const {address} = useAccount();
    // const address = "0x15A178931eAd5e2fe6F33C7917cb1cc3E00ec1a9";

    const [Pack_Is, SetPack_Is] = useState();
    const [Pack_List, SetPack_List] = useState([]);

    useEffect(() => { 
        const Load = async() => {
            try {
                SetPack_Is(Loading_Component());
                SetPack_List([]);

                const Arr = [];
                for(let i = 1; i <= 12; i++) {
                    
                    let total_Spo_Lvl = BigInt(0);
                    for(let lvl = 1; lvl <= 3; lvl++) {
                        let Spo_Pack_Lvl_Total_Reward = await client.readContract({
                            address: Pack_Activation.address,
                            abi: Pack_Activation.abi,
                            functionName: "GetSpo_Pack_Lvl_Total_Reward",
                            args: [address, Number(i), Number(lvl)]
                        });
                        total_Spo_Lvl += BigInt(Spo_Pack_Lvl_Total_Reward);
                    }
                    
                    let Upid_50_Pack_Total_Reward = await client.readContract({
                        address: Pack_Activation.address,
                        abi: Pack_Activation.abi,
                        functionName: "GetUpid_50_Pack_Total_Reward",
                        args: [address, Number(i)]
                    });
                    let Total_Rank_Income = (total_Spo_Lvl + Upid_50_Pack_Total_Reward);
                    Arr.push({
                        sr: i,
                        rank: Is_Rank(Number(i)),
                        income: (Is_Value_Format(Total_Rank_Income))
                    });
                }
                SetPack_List(Arr);
                SetPack_Is("");
            }
            catch(error) {
                console.log(error);
                SetPack_Is("");
                SetPack_List([]);
            }
        }
        
        if(address) {
            Load();
        }
    }, [address]);
    
    return (
        <>
            <div class="hide-space"></div>
            <div class="back-grid-short">                                 
                <div class="heading">
                    <span>Rank Income</span>
                    {/* <div class="card-next-previous">
                        <span class="pre-btn"><button>pre</button></span>
                        <span>20/100</span>
                        <span class="next-btn"><button>Next</button></span>  
                    </div> */}
                </div>
            </div>
            <div class="back-grid">
                <div class="table scroll-bar">
                    <table>
                        <thead>
                            <td>Sr</td>
                            <td>Rank</td>
                            <td>Income</td>
                            <td>Tikker</td>
                        </thead>
                        <tbody>
                            {
                                Pack_Is === "" ? (
                                    <>
                                        {
                                            Pack_List.map((data) => {
                                                return (
                                                    <tr>
                                                        <td>{data.sr}</td>
                                                        <td>{data.rank}</td>
                                                        <td>{data.income}</td>
                                                        <td>
                                                            <div class="c-logo-grid">
                                                                <span><img src={core_3} alt=""/></span>
                                                            </div>
                                                        </td>
                                                    </tr> 
                                                );
                                            })
                                        }
                                    </>
                                ) : (
                                    <>
                                        <tr>
                                            <td></td>
                                            <td>{Pack_Is}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </>
                                )
                            } 
                        </tbody>
                    </table>
                </div> 
            </div>
        </>
    );
}

//----My_Rank--------

export function Recent_Form(props) {

    const {address} = useAccount();
    // const address = "0x15A178931eAd5e2fe6F33C7917cb1cc3E00ec1a9";

    const [Total_Page, SetTotal_Page] = useState(Number(0));
    const [Current_Page, SetCurrent_Page] = useState(Number(0));

    const [Refferal_Is, SetRefferal_Is] = useState("");
    const [Recent_List, SetRecent_List] = useState([]);

    const Current_Page_Detail = async(cur_page) => {
        try {
            SetRefferal_Is(Loading_Component());

            let from = Number(Number(Number(cur_page) - Number(1)) * Number(20) + Number(1));
            let to = Number(Number(cur_page) * Number(20)); 
            //console.log(from + " from");
            //console.log(to + " to");

            SetRecent_List([]);
            let Arr = [];
            for(let i = from; i <= to; i++) {
                let RR_Add_No_Add = await client.readContract({
                    address: Pack_Activation.address,
                    abi: Pack_Activation.abi,
                    functionName: "GetRR_Add_No_Add",
                    args: [address, Number(i)]
                });

                if(RR_Add_No_Add[1] !== "0x0000000000000000000000000000000000000000") {
                    
                    let time = await Format_UTC(Number(RR_Add_No_Add[6]));

                    let type_ = "";
                    let layer_ = "";
                    if(RR_Add_No_Add[2] === "SPO") {
                        type_ = "Refferal";
                        layer_ = "Reff Layer: ";
                    }
                    else {
                        type_ = "Level";
                        layer_ = "Level Layer: ";
                    }

                    Arr.push({
                        sr: i,
                        from: Sub_Variable(RR_Add_No_Add[1]),
                        amt: Is_Value_Format(Number(RR_Add_No_Add[5])),
                        type: type_,
                        rank: Is_Rank(Number(RR_Add_No_Add[3])),
                        layer: layer_ + RR_Add_No_Add[4],
                        time: time
                    });
                }
                else {
                    break;
                }
            }
            SetRecent_List(Arr);
            SetRefferal_Is("");
            //console.log(Recent_List);
        }
        catch(error) {
            console.log(error);
            SetRefferal_Is("");
            SetRecent_List([]);
        }
    }

    const Next = async() => {
        let nextpage = Number(Number(Current_Page) + Number(1));
        if(Number(nextpage) > Number(Total_Page)) {

        }
        else if(Number(nextpage) <= Number(Total_Page)) {
            SetCurrent_Page(nextpage);
            Current_Page_Detail(nextpage);
        }
    }
    const Pre = async() => {
        let prepage = Number(Number(Current_Page) - Number(1));
        if(Number(prepage) < Number(1)) {

        }
        else if(Number(prepage) >= Number(1)) {
            SetCurrent_Page(prepage);
            Current_Page_Detail(prepage);
        }
    }
    
    useEffect(() => {
        const First_Load = async() => {
            try {
                SetRefferal_Is(Loading_Component());
                let RR_Add_Total_No = await client.readContract({
                    address: Pack_Activation.address,
                    abi: Pack_Activation.abi,
                    functionName: "GetRR_Add_Total_No",
                    args: [address]
                });
                //console.log(Number(RR_Add_Total_No));
                let cur_page = await Return_Page_Settings(Number(RR_Add_Total_No));
                //console.log("cur_page : " + cur_page);
                SetTotal_Page(Number(cur_page));
                SetCurrent_Page(Number(cur_page));

                //console.log("Current_Page : " + Current_Page);
                //console.log("Total_Page : " + Total_Page);
                Current_Page_Detail(Number(cur_page));
            }
            catch(error) {
                console.log(error);
                SetRefferal_Is("");
            }
        }
        
        if(address) {
            First_Load();
        }
    }, [address]);

    return (
        <>
            <div class="hide-space"></div>
            <div class="back-grid-short">                                 
                <div class="heading">
                    <span>Recent Income</span>
                    <div class="card-next-previous">
                        <span class="pre-btn"><button onClick={() => {Pre();}}>Pre</button></span>
                        <span>{Current_Page} / {Total_Page}</span>
                        <span class="next-btn"><button onClick={() => {Next();}}>Next</button></span>  
                    </div>
                </div>
            </div>
            <div class="back-grid">
                <div class="table scroll-bar">
                    <table>
                        <thead>
                            <td>Sr</td>
                            <td>From</td>
                            <td>Amount</td>
                            <td>Type</td>
                            <td>TIKKER</td>
                            <td>Rank</td>
                            <td>Layer</td>
                            <td>Time</td>
                        </thead>
                        <tbody>
                            {
                                Refferal_Is === "" ? (
                                    <>
                                        {
                                            Recent_List.map((data) => {
                                                return (
                                                    <tr>
                                                        <td>{data.sr}</td>
                                                        <td>{data.from}</td>
                                                        <td>{data.amt}</td>
                                                        <td>{data.type}</td>
                                                        <td>CORE</td>
                                                        <td>{data.rank}</td>
                                                        <td>{data.layer}</td>
                                                        <td>{data.time}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </>
                                ) : (
                                    <>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{Refferal_Is}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </>
                                )
                            }
                        </tbody>
                    </table>
                </div> 
            </div>
        </>
    );
}

//---------------------Before-Login---------

//-----Refferal_Area-------------------------
async function Generate_ID() {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    for(let c = 1; c <= Number(8); c++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
export async function Refferal_Detail(id, SetUpline_Address, open, showAlert, SetLoadingModal) {
    try {
      SetLoadingModal(true, true);
      let upline_id_exist = await client.readContract({
        address: FS_Main.address,
        abi: FS_Main.abi,
        functionName: "GetUser_ID_Exist",
        args: [id]
      });
  
      if (upline_id_exist === true) {
        let upline_id_type = await client.readContract({
          address: FS_Main.address,
          abi: FS_Main.abi,
          functionName: "GetUser_ID_ID_Type",
          args: [id]
        });
  
        if (upline_id_type === 'S1' || upline_id_type === 'S2' || upline_id_type === 'S3') {
  
          let upline_id_address = await client.readContract({
            address: FS_Main.address,
            abi: FS_Main.abi,
            functionName: "GetUser_ID_Address",
            args: [id]
          });
  
          let upline_add_detail = await client.readContract({
            address: FS_Main.address,
            abi: FS_Main.abi,
            functionName: "GetUser_Detail",
            args: [upline_id_address]
          });
  
          if (upline_add_detail[6] === true) {
            SetUpline_Address(upline_id_address);
            SetLoadingModal(false, true); 
            open();
          }
          else {
            showAlert("Upline ID isn't Activated!...", 'danger', true);
            SetLoadingModal(false, false);  
          }
        }
        else if (upline_id_type === 'S0'){
          //console.log("IS S0");
          showAlert("Can't SignUp!...", "danger", true);
          SetLoadingModal(false, false);  
        }
      }
      else if (upline_id_exist === false) {
        SetLoadingModal(false, false);  
        showAlert("Doesn't Exist", "danger", true);
      }
    }
    catch(error) {
      SetLoadingModal(false, false);    
    }
}
export async function Refferal_Free_SignUp(address, Upline_Address, showAlert, SetLoadingModal) {
    try {
      SetLoadingModal(true, true);
      let add_detail = await client.readContract({
        address: FS_Main.address,
        abi: FS_Main.abi,
        functionName: "GetUser_Address_Exist",
        args: [address]
      }); 
  
      if (add_detail === true) {
        SetLoadingModal(false, false);
        showAlert("You Already Exist!...", "danger", true);
      }
      else {
        SetLoadingModal(true, true);
        if (window.ethereum) {
          // Delcalre a Wallet Client
          const walletClient = createWalletClient({
            account: address,
            chain: p_chain,
            transport: custom(window.ethereum)
          });
  
          //console.log("WalletClient");
          //console.log(walletClient);
          let id_;
          do{
            id_ = await Generate_ID();
            let id_exist = await client.readContract({
                address: FS_Main.address,
                abi: FS_Main.abi,
                functionName: "GetUser_ID_Exist",
                args: [id_]
            });
            if (id_exist === false) {
                break;
            }
          }
          while(true);
          
          SetLoadingModal(true, false);
          let request_writeContract = await walletClient.writeContract({
            address: FS_Main.address,
            abi: FS_Main.abi,
            functionName: "Free_Signup_Con",
            args: [address, Upline_Address, id_],
            account: address
          });
          SetLoadingModal(true, true);
          //console.log(request_writeContract);
          
          const transaction = await client.waitForTransactionReceipt({confirmations: 5, hash: request_writeContract});
  
          if (transaction.status === "success") {
            SetLoadingModal(false, false);
            showAlert("You SuccessFully SignedUp!...", "success", true);
            setTimeout(() => {
              window.location.replace(url);
            }, [4000]);
          }
          else {
            SetLoadingModal(false, false);
            showAlert("Your Transaction Is Reverted", "danger", true);
          }
          
        }
        else {
          SetLoadingModal(false, false);
          showAlert("Please SignUp Through DAPPS!...", "warning", false);
        }
      }
    
    }
    catch(error) {
      //console.log("Step Falied");
      console.log(error);
      //showAlert(error.toString(), "danger", false);
      SetLoadingModal(false, false);
    }
}

//-----Login_Area----------------------------


export async function Login_Detail(address, SetIsLogin, showAlert) {
    try {
      let add_detail = await client.readContract({
        address: FS_Main.address,
        abi: FS_Main.abi,
        functionName: "GetUser_Address_Exist",
        args: [address]
      });      
      
      if (add_detail === true) {
        SetIsLogin(true);
        showAlert("SuccessFully Logined!...", "success", true);
      }
      else if (add_detail === false) {
        SetIsLogin(false);
        showAlert("You Doesn't Exist!...", "danger", true);
      }
    }
    catch (error) {
        console.log(error);
    }
}
